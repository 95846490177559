import React from 'react';

import { AuthenticationLayout } from './AuthenticationLayout';
import SignUpForm from './SignUpForm';

export const SignUpPage = () => (
  <AuthenticationLayout>
    <SignUpForm />
  </AuthenticationLayout>
);
