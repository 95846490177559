import React from 'react';
import { AuthenticationLayout } from './AuthenticationLayout';
import LoginForm from './LoginForm';

export const LoginPage = () => {
  return (
    <AuthenticationLayout>
      <LoginForm />
    </AuthenticationLayout>
  );
};
