import React, { useEffect, Fragment } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslate } from 'ra-core';
import { required } from '@bappweb/utils';
import Form from '@bappweb/form';
import Block from '@bappweb/block';
import TextField from '../components/TextField';
import { useResetPassword } from 'layer7-apihub';
import { Link as RouterLink } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useLayer7Notify } from 'layer7-apihub';

const ResetPasswordForm = () => {
  const classes = useStyles();
  const t = useTranslate();
  const notify = useLayer7Notify();
  const { ENVIRONMENT } = window.APIHUB_CONFIG;

  useEffect(() => {
    loadCaptchaEnginge(8, '#f5f4f6', 'black', 'upper');
  }, []);

  const handleError = (e, errors) => {
    console.info(JSON.stringify(errors, void 0, 2));
  };

  const [username, setUsername] = useResetPassword();

  const handleSubmit = (e, data) => {
    if (
      validateCaptcha(data.captcha) === true ||
      (ENVIRONMENT === 'Uat' && data.captcha === '123456') // Added for testing on UAT environment
    ) {
      loadCaptchaEnginge(8, '#f5f4f6', 'black', 'upper');
    } else {
      notify(t('captcha.error'), 'warning');
      return;
    }

    setUsername(data.username);
  };

  return (
    <Block className={classes.bappAppPageResetPasswordMain}>
      <Block className={classes.bappPageResetPasswordMainWrapper}>
        <Typography variant="h2" className={classes.title}>
          {t('resetPassword.title')}
        </Typography>
        {!username && (
          <Fragment>
            <Typography variant="h5" className={classes.infoTitle}>
              {t('resetPassword.infoTitle')}
            </Typography>
            <Typography variant="p" className={classes.info}>
              {t('resetPassword.info')}
            </Typography>
            <Form
              onSubmit={handleSubmit}
              onError={handleError}
              className={classes.resetPasswordForm}
              grid={false}
            >
              <TextField
                label={t('resetPassword.form.username')}
                placeholder={t('resetPassword.form.username_placeholder')}
                type="text"
                name="username"
                validations={[required(t('validation.required'))]}
              />

              <div className={classes.captchaStyle}>
                <LoadCanvasTemplate reloadText={t('captcha.reload')} reloadColor="#dc0005" />
              </div>

              <TextField
                label={t('captcha.text')}
                placeholder=""
                type="text"
                name="captcha"
                validations={[required(t('validation.required'))]}
              />
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                className={[classes.submit, classes.button]}
                size="large"
              >
                {t('resetPassword.form.resetPassword')}
              </Button>
            </Form>
          </Fragment>
        )}
        {username && (
          <Fragment>
            <Typography variant="h5" className={classes.infoTitle}>
              {t('resetPassword.checkYourEmailTitle')}
            </Typography>
            <Typography variant="p" className={classes.info}>
              {t('resetPassword.checkYourEmailDescription')}
            </Typography>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              className={classes.submit}
              size="large"
              to="/login"
              component={RouterLink}
            >
              {t('resetPassword.login')}
            </Button>
          </Fragment>
        )}
      </Block>
    </Block>
  );
};

const useStyles = makeStyles(theme => ({
  bappAppPageResetPasswordMain: {
    marginTop: 100,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    marginBottom: '32px',
    fontSize: '24px',
  },
  bappPageResetPasswordMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resetPasswordForm: {
    width: '100%',
  },
  submit: {
    marginTop: 24,
  },
  infoTitle: {
    paddingTop: 24,
    alignSelf: 'flex-start',
    marginLeft: 20,
  },
  info: {
    alignSelf: 'flex-start',
    marginLeft: 20,
    paddingTop: 8,
    paddingBottom: 12,
  },
  button: {
    maxWidth: 335,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  captchaStyle: {
    border: '1px solid rgb(204, 204, 204)',
    textAlign: 'center',
    margin: '10px auto 6px',
    padding: '10px',
    width: '90%',
    boxSizing: 'border-box',
  },
}));

export default ResetPasswordForm;
