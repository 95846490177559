const DataLayer = (event, name, place, from, to) => {
  // eslint-disable-next-line no-undef
  dataLayer.push({
    event: `${event}`,
    button_name: `${name}`,
    button_place: `${place}`,
    click_from: `/${from}`,
    click_to: `/${to}`,
  });
};

export default DataLayer;
