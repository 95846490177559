import React, { Fragment, useState, useEffect } from 'react';
import { useTranslate } from 'ra-core';
import {
  makeStyles,
  Typography,
  Container,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useCaseList from '../../files/use_cases_list.json';
import apiListEn from '../../files/home_api_list-en.json';
import Slider from '../../components/Slider';
import ApiCard from '../../components/ApiCard';
import { useLocale } from 'ra-core';
import Icon from '@bappweb/icon';

export const UseCaseDetail = () => {
  const t = useTranslate();
  const classes = useStyles();
  const { ENVIRONMENT } = global.APIHUB_CONFIG;
  const locale = useLocale();
  const getParam = useParams();
  let newSlider = [];

  const useCases =
    locale === 'en' ? useCaseList[ENVIRONMENT].UseCasesEN : useCaseList[ENVIRONMENT].UseCasesTR;

  const apiSlider =
    locale === 'en'
      ? apiListEn[ENVIRONMENT].OurApisSectionEN
      : apiListEn[ENVIRONMENT].OurApisSectionTR;

  const useCaseContent = useCases.filter(card => {
    return card.uuid == getParam.getId;
  });

  const apiSliderContent = apiSlider.forEach(el => {
    useCaseContent.forEach(val => {
      if (val.initial.includes(el.uuid)) return newSlider.push(el);
    });
  });

  useEffect(() => {
    apiSliderContent;
  }, []);

  return (
    <Fragment>
      {useCaseContent.map((c, index) => {
        return (
          <Fragment>
            <Container>
              <Breadcrumbs
                separator={<Icon name="NavigationRight" size={22} />}
                className={classes.breadcrumps}
              >
                <Link color="inherit" to="/" component={RouterLink}>
                  {t('common.home')}
                </Link>
                <Link color="inherit" to="/use-cases" component={RouterLink}>
                  {t('home.useCasesHeader')}
                </Link>
                <Typography color="textPrimary"> {c.name}</Typography>
              </Breadcrumbs>
              <div className={classes.header}>
                <Typography variant="h2" className={classes.mainHeader}>
                  {c.name}
                </Typography>
              </div>
            </Container>
            <div className={classes.section}>
              <Container>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      variant="h6"
                      className={classes.mainHeaderSubtitle}
                      color="textPrimary"
                      gutterBottom={true}
                    >
                      {c.description}
                    </Typography>
                    <Typography color="textSecondary">{c.content}</Typography>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} key={'api_list_' + c.uuid + index}>
                    <img className={classes.detailImage} src={c.url} />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fragment>
        );
      })}
      <div className={classes.section}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.sliderHeader}>
                {t('usecases.sliderTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider fullWidthButtons={false} slidesToShow={3}>
                {newSlider.map((card, i) => (
                  <ApiCard
                    key={'api_card' + card.id}
                    {...card}
                    even={i % 2 === 0}
                    buttonName={t('home.sliderButton')}
                  />
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '100px',
  },
  header: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: '40px',
  },
  mainHeader: {
    marginTop: '60px',
    flexBasis: '10%',
    marginBottom: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  mainHeaderSubtitle: {
    marginBottom: '40px',
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  detailImage: {
    maxWidth: '100%',
    borderRadius: 5,
  },
  breadcrumps: {
    height: 56,
    marginBottom: 40,
    marginTop: 20,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 10,
    },
  },
  section: {
    paddingTop: 64,
    paddingBottom: 64,
    marginBottom: 64,
    backgroundColor: 'white',
  },
  sliderHeader: {
    marginBottom: 40,
  },
}));
