import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Header } from './Header';
import { Footer } from './Footer';
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { useTheme } from '../theme';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useTranslate } from 'ra-core';

export const Layout = props => {
  let { theme } = useTheme();
  theme = createMuiTheme(theme);
  theme = responsiveFontSizes(theme);
  const { pathname } = useLocation();
  const t = useTranslate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="description" content={t('metaTags.description')} />
      </Helmet>
      <Header />
      {props.children}
      <Footer />
    </ThemeProvider>
  );
};
export const guessApihubTenantName = (location = global.window.location) => {
  return location.host.split('.')[0];
};

export const guessApihubUrl = (location = global.window.location) => {
  return get(location, 'origin', '');
};
