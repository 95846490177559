import { applications as defaultApplications } from 'layer7-apihub';
import { ApplicationCreate } from './ApplicationCreate';
import { ApplicationEdit } from './ApplicationEdit';
import { ApplicationShow } from './ApplicationShow';

export const applications = {
  ...defaultApplications,
  show: ApplicationShow,
  edit: ApplicationEdit,
  create: ApplicationCreate,
};
