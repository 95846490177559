import Element from '@bappweb/element';
import { makeStyles } from '@material-ui/core';

const CompanySvg = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <Element
      {...rest}
      type="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={148}
      height={16}
      viewBox="0 0 148 16"
      fill="none"
      className={`${classes.bappLogo} ${className ? className : ''}`}
    >
      <Element
        type="path"
        d="m12.036 3.705 3.23 6.014H8.892l3.144-6.014zM0 16h5.569l1.456-2.82h10.1L18.682 16h5.553L15.29 0H8.8L0 16zM26.554 0h4.996v5.877h2.331L40.155 0h6.793L38.14 7.82 47.978 16h-7.056l-7.18-6.136H31.55V16h-4.996V0zM55.379 9.658h8.93c1.084 0 1.805.236 1.805 1.319 0 .922-.72 1.166-1.805 1.166h-8.93V9.658zM50.39 16h15.878c2.842 0 4.957-.732 4.957-4.238 0-2.409-.821-3.56-2.99-3.834v-.054c1.386-.404 2.409-.914 2.409-3.674 0-3.14-1.937-4.2-5.925-4.2H50.39v16zm4.988-12.15H64.2c.929 0 1.456.32 1.456 1.25 0 .922-.527 1.242-1.456 1.242h-8.822V3.85zM85.228 3.705l3.238 6.014h-6.374l3.136-6.014zM73.2 16h5.569l1.448-2.82h10.116L91.866 16h5.538L88.482 0h-6.483L73.2 16zM99.75 0h7.73l9.356 11.64h.047V0h4.98v16h-7.722l-9.364-11.617h-.031V16H99.75V0zM125.808 0h4.965v5.877h2.339L139.401 0h6.77l-8.791 7.82 9.821 8.18h-7.033l-7.187-6.136h-2.208V16h-4.965V0z"
        fill="#fff"
      />
    </Element>
  );
};

const useStyles = makeStyles(theme => ({
  bappLogo: {
    //marginRight: '75px',
  },
}));

export default CompanySvg;
