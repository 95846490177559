import { createMuiTheme } from '@material-ui/core';
import { colors } from './colors';
import { fontWeightMap } from './constants';

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary_akbank_red,
    },
    background: {
      default: colors.primary_akbank_red,
      paper: colors.secondary_off_white,
    },
    text: {
      secondary: colors.secondary_gray_secondary,
    },
    grey: {
      '500': colors.secondary_gray_tertiary,
    },
  },
  typography: {
    fontFamily: ['Campton', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 28,
      },
      outlined: {
        borderWidth: '2px !important',
        borderColor: colors.primary_akbank_red + ' !important',
        '& > .MuiButton-label': {
          color: colors.primary_akbank_red + ' !important',
        },
        padding: '6px 15px',
      },
      sizeLarge: {
        fontSize: '1rem',
        padding: '14px 22px',
      },
      text: {
        textDecoration: 'underline',
        padding: '6px 0',
        '&:hover': {
          backgroundColor: 'transparent !important',
          textDecoration: 'underline',
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: fontWeightMap.semiBold,
        lineHeight: 1.43,
        textTransform: 'none',
        letterSpacing: 0.5,
        cursor: 'pointer',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '3em',
        fontWeight: fontWeightMap.medium,
        letterSpacing: 'initial',
        lineHeight: 'initial',
      },
      h5: {
        fontSize: 'initial',
        fontWeight: fontWeightMap.semiBold,
        letterSpacing: 'initial',
        lineHeight: 'initial',
      },
      h6: {
        fontSize: '1.125em',
        fontWeight: fontWeightMap.regular,
        letterSpacing: '0.22px',
        lineHeight: '24px',
      },
      p: {
        fontSize: 'initial',
        fontWeight: fontWeightMap.semiBold,
        letterSpacing: 'initial',
        lineHeight: 'initial',
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        margin: '0 !important',
      },
    },
  },
});

export default lightTheme;
