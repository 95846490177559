import React from 'react';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import { Notification } from 'react-admin';
import { useTheme } from '../theme';
import { Header } from '../layout/Header';
import { SideContent } from './SideContent';

export const AuthenticationLayout = props => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={createMuiTheme(theme)}>
      <ThemedAuthenticationLayout {...props} />
    </ThemeProvider>
  );
};

const ThemedAuthenticationLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.container}>
        <Hidden xsDown>
          <div className={classes.leftColumn}>
            <SideContent />
          </div>
        </Hidden>
        <div className={classes.rightColumn}>{children}</div>
      </div>
      <Notification />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100vh',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      maxWidth: '100%',
      width: '100%',
    },
    backgroundColor: theme.palette.background.default,
    paddingLeft: '5%',
    paddingRight: '5%',
    minWidth: '33%',
    maxWidth: '33%',
    position: 'relative',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    minWidth: '57%',
    alignItems: 'center',
    flexGrow: 1,
  },
}));
