import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { supportedLocales, defaultLocale } from 'layer7-apihub';
import { Button } from '@material-ui/core';
import { useSetLocale, useLocale } from 'ra-core';
import { useApiHubPreference } from 'layer7-apihub';

const LangButton = withStyles(() => ({
  root: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))(props => <Button {...props} />);

export const LanguageSwitcher = () => {
  const setLocale = useSetLocale();
  const locale = useLocale();
  const [localePreference, writeLocalePreference] = useApiHubPreference('locale');

  useEffect(() => {
    if (!supportedLocales[localePreference]) {
      writeLocalePreference(defaultLocale);
      return;
    }

    if (localePreference !== locale) {
      setLocale(localePreference);
    }
  }, [locale, localePreference, setLocale, writeLocalePreference]);

  const handleLocaleChange = () => {
    writeLocalePreference(locale === 'tr' ? 'en' : 'tr');
  };

  return <LangButton onClick={handleLocaleChange}>{locale === 'tr' ? 'EN' : 'TR'}</LangButton>;
};
