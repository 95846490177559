import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './en';
import turkishMessages from './tr';

export const i18nProvider = (defaultLocale = 'tr') =>
  polyglotI18nProvider(
    locale => {
      if (locale === 'en') {
        return englishMessages;
      }
      if (locale === 'tr') {
        return turkishMessages;
      }
    },
    defaultLocale // Default locale
  );
