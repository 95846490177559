import React, { useState } from 'react';
import { makeStyles, Typography, Container, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslate } from 'ra-core';
import { Pagination } from '@material-ui/lab';
import ContentLoader from 'react-content-loader';
import DataLayer from '../../core/Ga_dataLayer';
import { useAuthState } from 'ra-core';

export const ApiList = () => {
  const t = useTranslate();
  const classes = useStyles();
  const [apiResult, setApiResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { authenticated } = useAuthState();

  React.useEffect(() => {
    const { APIHUB_URL, ORIGIN_HUB_NAME, TENANT_NAME } = window.APIHUB_CONFIG;
    const hostName = window.location.host;

    //console.log('test', APIHUB_URL, ORIGIN_HUB_NAME, TENANT_NAME, hostName);
    const myHeaders = new Headers();
    myHeaders.append('Access-Control-Request-Headers', 'origin-hub-name');
    myHeaders.append('Host', 'https://apiportal.akbank.com');
    myHeaders.append('Origin', hostName);
    myHeaders.append('Referer', hostName + '/');
    myHeaders.append('origin-hub-name', ORIGIN_HUB_NAME);
    myHeaders.append('Sec-Fetch-Mode', 'cors');
    myHeaders.append('Sec-Fetch-Site', 'cross-site');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const url = `https://apiportal.akbank.com/api/${TENANT_NAME}/api-management/1.0/api-catalogs?page=${currentPage -
      1}&accessStatus=public`;

    fetch(url, requestOptions)
      .then(p => p.json())
      .then(p => {
        setApiResult(p);
        setLoading(false);
      })
      .catch(c => {
        setLoading(false);
      });
  }, [currentPage]);

  const onPageChange = (event, page) => {
    window.scrollTo(0, 0);
    setLoading(true);
    setCurrentPage(page);
  };

  const placeholder = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i, k) => {
    return (
      <ContentLoader speed={1} width={555} height={244} background="#f3f3f3">
        <rect x="0" y="34" rx="0" ry="0" width="0" height="0" />
        <rect x="28" y="29" rx="0" ry="0" width="255" height="32" />
        <rect x="28" y="91" rx="0" ry="0" width="555" height="32" />
        <rect x="28" y="134" rx="0" ry="0" width="555" height="32" />
      </ContentLoader>
    );
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.header}>
          <Typography variant="h2" className={classes.mainHeader}>
            {t('apis.header')}
          </Typography>
          <Grid container>
            <Grid item xs={12} xl={6}>
              <Typography variant="h6" className={classes.mainHeaderSubtitle} color="textSecondary">
                {t('apis.subHeader')}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.apiListContainer}>
          <div className={classes.apiList}>
            {loading && (
              <Grid container justify="center">
                <Grid item>{placeholder}</Grid>
              </Grid>
            )}
            <div className={classes.apiGrid}>
              {!loading &&
                apiResult &&
                apiResult.results &&
                apiResult.results.map((api, index) => {
                  return (
                    <div
                      key={'api_list_' + api.uuid + index}
                      className={clsx({
                        [classes.apiCard]: true,
                        [classes.apiCardBgDark]: index % 3 === 0,
                      })}
                    >
                      <Typography variant="h5" className={classes.apiCardHeader}>
                        {api.name}
                      </Typography>
                      <p className={classes.apiCardDescription}>{api.description}</p>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.apiCardDetailButton}
                        component={Link}
                        to={'/apis/' + api.uuid + '/show'}
                        onClick={() =>
                          DataLayer(
                            'button_click',
                            `${api.name}`,
                            'body',
                            window.location.hash,
                            authenticated ? `${'apis/' + api.uuid + '/show'}` : 'login'
                          )
                        }
                      >
                        {t('apis.apisButton')}
                      </Button>
                    </div>
                  );
                })}
            </div>
            {!loading && apiResult && apiResult.results && (
              <Grid container justify="center">
                <Grid item>
                  <Pagination
                    count={apiResult.totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={onPageChange}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '100px',
  },
  header: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: '40px',
  },
  mainHeader: {
    marginTop: '60px',
    flexBasis: '10%',
    marginBottom: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  mainHeaderSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  apiListContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
  },
  filterSidebar: {
    borderRadius: '12px',
    backgroundColor: '#fff',
    minHeight: '300px',
    width: '310px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08);',
    padding: '32px 24px',
    maxHeight: '300px',
  },
  filterHeader: {
    color: '#202020',
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 8,
  },
  filterSearchWrapper: {
    marginBottom: '30px',
  },
  filterCategories: {
    listStyle: 'none',
    padding: 0,
  },
  filterCategoryName: {
    color: '#5d5864',
  },
  apiList: {
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    padding: '44px 56px 56px 55px;',
    backgroundColor: '#fff',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      padding: '10px',
    },
  },
  apiGrid: {
    gridTemplateColumns: '1fr 1fr',
    gridAutoFlow: 'row dense',
    columnGap: '10px',
    rowGap: '15px',
    display: 'grid',
    marginBottom: '50px',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr);',
    },
  },
  apiCategoryHeader: {
    color: '#202020',
    margin: '0 25px 20px 0',
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 500,
  },
  apiCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    height: '200px',
    padding: '20px 20px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  apiCardBgDark: {
    backgroundColor: theme.palette.grey['500'],
  },
  apiCardHeader: {
    color: '#202020',
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 500,
  },
  apiCardDescription: {
    color: '#5d5864',
    fontSize: theme.typography.fontSize * 1.3,
  },
  apiCardDetailButton: {
    width: '124px',
    marginTop: 'auto',
  },
  filteredItemsCountText: {
    color: '#202020',
    fontWeight: 500,
  },
  searchTextField: {
    fontSize: '14px',
  },
}));
