import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import Block from '@bappweb/block';
import Element from '@bappweb/element';
import Form from '@bappweb/form';
import { required } from '@bappweb/utils';
import { Button, Typography, Link, Box } from '@material-ui/core';
import TextField from '../components/TextField';
import { useLogin } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { useLayer7Notify } from 'layer7-apihub';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const LoginForm = () => {
  const classes = useStyles();
  const t = useTranslate();
  const login = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useLayer7Notify();
  const { ENVIRONMENT } = window.APIHUB_CONFIG;

  useEffect(() => {
    loadCaptchaEnginge(8, '#f5f4f6', 'black', 'upper');
  }, []);

  const handleSubmit = async (e, data) => {
    if (isLoading) {
      return;
    }

    if (
      validateCaptcha(data.captcha) === true ||
      (ENVIRONMENT === 'Uat' && data.captcha === '123456') // Added for testing on UAT environment
    ) {
      loadCaptchaEnginge(8, '#f5f4f6', 'black', 'upper');
    } else {
      notify(t('captcha.error'), 'warning');
      return;
    }

    const { username, password } = data;
    if (!username || !password) {
      return;
    }

    setIsLoading(true);
    try {
      await login({ scheme: 'credentials', username, password });
    } catch (e) {
      notify(t('login.invalid_credentials'), 'warning');
    }
    setIsLoading(false);
  };

  const handleError = (e, errors) => {
    console.log(errors);
  };

  return (
    <Block className={classes.bappAppPageLoginMain}>
      <Block className={classes.bappPageLoginMainWrapper}>
        <Typography variant="h2" className={classes.title}>
          {t('login.login_title')}
        </Typography>
        <Form
          className={classes.loginForm}
          onSubmit={handleSubmit}
          onError={handleError}
          grid={false}
        >
          <TextField
            label={t('login.form.username')}
            placeholder={t('login.form.username_placeholder')}
            type="text"
            name="username"
            validations={[required(t('validation.required'))]}
          />
          <TextField
            label={t('login.form.password')}
            placeholder={t('login.form.password_placeholder')}
            type="password"
            name="password"
            validations={[required(t('validation.required'))]}
          />

          <div className={classes.captchaStyle}>
            <LoadCanvasTemplate reloadText={t('captcha.reload')} reloadColor="#dc0005" />
          </div>

          <TextField
            label={t('captcha.text')}
            placeholder=""
            type="text"
            name="captcha"
            validations={[required(t('validation.required'))]}
          />

          <Button
            color="primary"
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            disabled={isLoading}
            className={[classes.submit, classes.button]}
            disableElevation
          >
            {t('login.form.submit')}
          </Button>
        </Form>
        <Link
          variant="button"
          className={classes.forgotPassword}
          to="/reset-password"
          component={RouterLink}
          underline="always"
        >
          {t('login.form.forgot_password')}
        </Link>
        <Element className={classes.or} type="hr" data-text={t('login.form.or')} />
        <Button
          className={classes.button}
          fullWidth
          color="primary"
          variant="outlined"
          size="large"
          to="/signup"
          component={RouterLink}
        >
          {t('login.form.sign_up')}
        </Button>
        <Typography component="div" variant="body2">
          <Box className={classes.version} color="text.hint">
            Version 5.0
          </Box>
        </Typography>
      </Block>
    </Block>
  );
};

const useStyles = makeStyles(theme => ({
  bappAppPageLoginMain: {
    marginTop: 100,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    marginBottom: '32px',
    fontSize: '24px',
  },
  bappPageLoginMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginForm: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  forgotPassword: {
    padding: 0,
    marginTop: 32,
  },
  version: {
    color: theme.palette.text.hint,
    padding: '48px 24px 32px',
  },
  or: {
    position: 'relative',
    overflow: 'visible',
    margin: '48px 0',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.text.hint,
    width: '100%',

    '&::before': {
      color: theme.palette.text.hint,
      content: 'attr(data-text) ""',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: 10,
      borderRadius: 16,
      backgroundColor: theme.palette.background.paper,
    },
  },
  submit: {
    marginTop: 24,
  },
  captchaStyle: {
    border: '1px solid rgb(204, 204, 204)',
    textAlign: 'center',
    margin: '10px auto 0',
    padding: '10px',
    width: '90%',
    boxSizing: 'border-box',
  },
  button: {
    maxWidth: 335,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default LoginForm;
