import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CHANGE_THEME } from './constants';
import lightTheme from './light';

export const themeReducer = (previousState = 'light', { type, payload }) => {
  if (type === CHANGE_THEME) {
    return payload;
  }
  return previousState;
};

export const changeTheme = newTheme => ({
  type: CHANGE_THEME,
  payload: newTheme,
});

export const useTheme = () => {
  const themeMode = useSelector(state => state.theme);

  const theme = useMemo(() => {
    return themeMode === 'light' ? lightTheme : lightTheme;
  }, [themeMode]);

  return { theme };
};
