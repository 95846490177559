import React from 'react';
import { ApiHubAdmin, readApiHubPreference } from 'layer7-apihub';
import { Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import {
  LoginPage,
  ResetPasswordPage,
  NewPasswordPage,
  AccountSetupPage,
  SignUpPage,
} from './authentication';
import { HowItWorks } from './pages/how-it-works/HowItWorks';
import { Faq } from './pages/faq/Faq';
import { ApiList } from './pages/api-list/ApiList';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Layout } from './layout/Layout';
import { HomePage } from './pages/home/HomePage';
import { themeReducer } from './theme';
import { i18nProvider } from './i18n/i18nProvider';
import { apis } from './apis';
import { applications } from './applications';
import { userContexts } from './userContexts';
import { UseCase } from './pages/use-case/UseCase';
import { UseCaseDetail } from './pages/use-case/UseCaseDetail';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => {
  const { APIHUB_URL, TENANT_NAME, ORIGIN_HUB_NAME } = global.APIHUB_CONFIG;

  if (!ORIGIN_HUB_NAME) {
    throw new Error(
      'Please provide a value for the ORIGIN_HUB_NAME parameter in your configuration file'
    );
  }

  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const themeModePreference = readApiHubPreference('themeMode', prefersDarkMode ? 'dark' : 'light');

  const initialState = {
    theme: themeModePreference,
  };

  const defaultLocaleFromPreferences = readApiHubPreference('locale');

  return (
    <ApiHubAdmin
      url={APIHUB_URL} // Will be guessed by ApiHubAdmin if not defined
      tenantName={TENANT_NAME} // Will be guessed by ApiHubAdmin if not defined
      originHubName={ORIGIN_HUB_NAME}
      dashboard={HomePage}
      layout={Layout}
      loginPage={LoginPage}
      resetPasswordPage={ResetPasswordPage}
      newPasswordPage={NewPasswordPage}
      accountSetupPage={AccountSetupPage}
      signUpPage={SignUpPage}
      customReducers={{ theme: themeReducer }}
      i18nProvider={i18nProvider(defaultLocaleFromPreferences)}
      initialState={initialState}
      customRoutes={[
        <Route path="/dashboard" component={Dashboard} />,
        <Route path="/how-it-works" component={HowItWorks} />,
        <Route path="/faq" component={Faq} />,
        <Route path="/api-list" component={ApiList} />,
        <Route path="/use-cases" component={UseCase} />,
        <Route path="/" exact component={HomePage} />,
        <Route path="/use-case-detail/:getId" component={UseCaseDetail} />,
      ]}
    >
      <Resource key="apis" name="apis" {...apis} />
      <Resource key="applications" name="applications" {...applications} />
      <Resource key="userContexts" name="userContexts" {...userContexts} />
    </ApiHubAdmin>
  );
};

export default App;
