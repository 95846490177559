import { mergeTranslations } from 'react-admin';
import raMessages from 'ra-language-english';
import { englishMessages } from 'layer7-apihub';

export default mergeTranslations(englishMessages, {
  metaTags: {
    description:
      'Hemen şimdi geleceği yazmaya başla! Akbank API portal ile ürünlerini geliştir, fikirlerini hayata geçir.',
  },
  example: {
    action: {
      toggle_dark_mode: 'Toggle dark mode',
    },
  },
  common: {
    home: 'Anasayfa',
  },
  header: {
    home: 'Ana sayfa',
    api: "API'ler",
    use_case: 'Kullanım Senaryoları',
    faq: 'SSS',
    login_signup: 'Giriş Yap / Kayıt Ol',
    myAccount: 'Hesabım',
    accountMenu: {
      dashboard: 'Kontrol Panelim',
      myProfile: 'Profilim',
      logout: 'Çıkış Yap',
    },
  },
  login: {
    page_motto: 'Herkes için yazılım geliştirme',
    login_title: "API Portal'e giriş yap",
    invalid_credentials: 'Hatalı kullanıcı adı veya şifre',
    form: {
      username: 'KULLANICI ADI',
      username_placeholder: 'Kullanıcı adınızı girin',
      password: 'ŞİFRE',
      password_placeholder: 'Şifrenizi girin',
      submit: 'Giriş Yap',
      forgot_password: 'Şifremi Unuttum',
      or: 'veya',
      sign_up: 'Şimdi Kayıt Ol',
    },
  },
  register: {
    title: "API Portal'e kayıt ol",
    form: {
      email: 'EPOSTA',
      email_placeholder: 'E-posta adresinizi girin',
      confirm_email: 'EPOSTA DOĞRULA',
      confirm_email_placeholder: 'E-posta adresinizi girin',
      organization: 'ORGANİZASYON',
      organization_placeholder: 'Şirket adını girin',
      organization_description: 'ORGANİZASYON TANIMI',
      organization_description_placeholder: 'Şirket açıklamasını girin',
      signup: 'Kayıt Ol',
    },
    registerSuccess: 'Kayıt işleminiz başarılı bir şekilde gerçekleşti',
    registerError: 'Kayıt işlemi sırasında bir hata oluştu',
    confirmation_title: 'E-Postanı Kontrol Et',
    confirmation:
      'Kayıt başarıyla alındı. Vermiş olduğun e-posta adresine bir doğrulama bildirimi gönderildi.',
    return_to_homepage: 'Anasayfaya git',
  },
  resetPassword: {
    title: 'Şifremi Yenile',
    infoTitle: 'E-posta adresini gir',
    info: 'Şifreni yenilemen için e-posta adresine bir link göndereceğiz',
    form: {
      email: 'EPOSTA',
      username: 'KULLANICI ADI',
      username_placeholder: 'Kullanıcı adınızı giriniz',
      email_placeholder: 'E-posta adresinizi girin',
      resetPassword: 'Şifre Gönder',
    },
    checkYourEmailTitle: 'E-postanızı kontrol edin',
    checkYourEmailDescription: 'Şifrenizi sıfırlamak için e-postanızdaki bağlantıya tıklayın',
    login: 'Giriş yap',
  },
  validation: {
    required: 'Zorunlu Alan',
    checkEmail: 'Geçerli bir e-posta adresi gir',
  },
  home: {
    heroHeader: 'Geleceği yazmaya başla',
    heroSubHeader: 'Yenilenen Akbank API portal ile ürünlerini geliştir, fikirlerini hayata geçir.',
    getStartedButton: 'Kullanmaya Başla',
    howItWorksButton: 'Nasıl Kullanılır?',
    bigApiCardHeader: 'Neler Yapabilirsin?',
    ourApisHeader: 'API`lerimiz',
    useCaseDetailButton: 'Detaylar',
    useCasesHeader: 'Kullanım Senaryoları',
    ourApisSubHeader:
      'Akbank API Portal üzerinden seçeceğiniz API’lar ile uygulamalarınıza müşterilerinizin ihtiyaçlarına uygun yeni özellikler ve araçlar ekleyebilirsiniz.',
    seeMoreButton: 'Tümü',
    learnButtonMutton: 'Daha Fazlası',
    whatCanYouDoItems: {
      item1Text: 'Döviz Kurları',
      item1Description: 'Bu API ile seçtiğin tarihe ait döviz kur listesine erişebilirsin.',
      item2Text: 'Kredi Faiz Oranları',
      item2Description:
        'Bu API güncel Akbank İhtiyaç Kredisi, Konut Kredisi ve Taşıt Kredisi kredi oranlarını döner.',
      item3Text: 'Kredi Ödeme Planı',
      item3Description:
        'Bu API ile seçtiğin kredi faizi ve vadesi için ödeme planını hesaplayabilirsin.',
    },
    sliderButton: 'Detaylar',
  },
  faq: {
    header: 'Sıkça Sorulan Sorular',
    subHeader: 'API`ler hakkında bilmek istediklerinizi burada bulabilirsiniz.',
    breadcrumbText: 'SSS',
    accordion: {
      titleOne: 'API`leri ticari amaçlarla kullanabilir miyim?',
      descOne:
        'Evet, Akbank ile kullanım şartlarını içeren ticari bir anlaşma yapıldıktan sonra API`ler ticari amaçlarla kullanılabilir.',
      titleTwo: 'Portali kullanmaya nasıl başlayabilirim?',
      descTwo: {
        title: `API'leri kullanarak geliştirmeye başlamak için aşağıdaki adımları izle:`,
        ruleOne: `1. Giriş Yap veya Kayıt Ol’a tıklayarak hesabını oluştur.`,
        ruleTwo: `2. Uygulamanı yarat ve API anahtarı al.`,
        ruleThree: `3. Kimlik doğrulama için API anahtarını kullanarak uygulamanı geliştir.`,
      },
      titleThree: 'Remote API`leri kullanarak geliştirmeye başlamak için neye ihtiyacım var?',
      descThree: {
        title: `Bir geliştiricinin, uygulama geliştirmek için şu adımları izlemesi gerekir:`,
        ruleOne: ` 1. Geliştirici olarak kaydol.`,
        ruleTwo: ` 2. Mevcut API belgelerini oku ve özellikleri incele.`,
        ruleThree: ` 3. Bir API anahtarı almak için uygulamanı oluştur.`,
        ruleFour: `4. API anahtarını kullanarak OAuth aracılığıyla kimlik doğrulaması al. Bunun için seninle bir erişim belirteci paylaşılacak.`,
        ruleFive: `5. Yetkilendirme almak ve API'leri çağırmak için erişim belirtecini kullan.`,
      },
      titleFour:
        'Geliştirme sırasında yapabileceğim Uzak API çağrılarının sayısında bir sınır var mı?',
      descFour:
        'Evet, korumalı alan API`leri şu anda saniyede 5 istek sınırıyla sınırlandırılmıştır.',
      titleFive: 'Geliştirmemi bitirdiğimde ne olacak?',
      descFive: {
        title:
          'Uygulamanı onaya sunmaya hazır olduğuna karar verdiğinde, izlemen gereken temel adımlar aşağıda listelenmiştir:',
        ruleOne: '1. Akbank’ın uygulamanı incelemesi için uygulamanı onaya gönder',
        ruleTwo:
          '2. Uygulamanı hayata geçirebilmen için ek bazı güvenlik ve sözleşme adımları işletmemiz gerekecek. Gelecek yönlendirmeleri takip et',
        ruleThree: '3. Bu adımlar tamamlandığında uygulaman hayata geçmeye hazır olacak',
        ruleFour: ' 4. Bu noktada uygulamanı kendi mecranda yayına alabilirsin',
      },
      titleSix: 'Geliştirme için dokümanları nerede bulabilirim?',
      descSix: 'Detaylı bilgi için API’ler sayfasını inceleyebilirsin',
    },
  },
  howItWorks: {
    header: 'Akbank API Portal Nasıl Kullanılır?',
    breadcrumbText: 'Nasıl Kullanılır?',
    step: 'Adım',
    steps: {
      step1Text: 'Portal`de hesap aç',
      step1Description: 'Kayıt Ol`a tıklayarak hesabını kolayca açabilirsin.',
      step2Text: 'Uygulamanı yarat',
      step2Description: 'Bir veya birden fazla API kullanarak uygulamanı oluşturabilirsin.',
      step3Text: 'API`leri test et',
      step3Description:
        'Uygulamaya eklediğin API`lerin dökümanlarını inceleyerek API testlerini gerçekleştirebilirsin.',
      step4Text: 'Uygulamanı hayata geçir',
      step4Description:
        'Uygulamanı hayata geçirebilmek için ek bazı güvenlik ve sözleşme adımlarını hayata geçirmemiz gerekecek. Daha fazla bilgi için bize ulaşabilirsin.',
    },
  },
  applicationStatus: {
    enabled: 'Aktif',
    disabled: 'Pasif',
    unpublished: 'Yayında Değil',
    applicationPendingApproval: 'Onay Bekliyor',
    editApplicationPendingApproval: 'Güncelleme Onayı Bekliyor',
    deprecated: 'Kullanımdan Kaldırıldı',
    rejected: 'Reddedildi',
  },
  dashboard: {
    breakcrumbText: 'Kontrol Panelim',
    header: 'Kontrol Paneline Hoşgeldin!',
    subHeader: 'API`leri kullanmaya başlamak için yeni uygulama oluşturabilirsin.',
    monitorYourApplication: 'Uygulamanı İzle',
    addNewApplication: 'Yeni Uygulama Ekle',
    addYourFirstApplication: 'İlk Uygulamanı Ekle',
    noProjectText: 'Henüz Hiç Uygulaman Yok',
    noProjectPermissionError: 'Proje oluşturabilmek için yetkiniz bulunmamaktadır.',
    createYourFirstApplicationText: 'İlk Uygulamanı Oluştur',
    myApplications: 'Uygulamalarım',
    gridApplicationNameColumnHeader: 'Uygulama Adı',
    gridStatusColumnHeader: 'Statü',
    gridActionsHeader: 'Aksiyon',
    whatsNextHeader: 'What is the next step?',
    whatsNextSubHeader: 'Follow these instructions to begin using Akbank API’s',
    unsureTextHeader: 'Yardıma mı ihtiyacın var?',
    unsureTextSubHeader: 'Akbank API`lerini kullanmaya başlamak için bu adımları izle',
    whatsNextItem1: ' Kayıt Ol`a tıklayarak hesabını kolayca açabilirsin.',
    whatsNextItem1Title: 'Portal`de hesap aç',
    whatsNextItem2: 'Bir veya birden fazla API kullanarak uygulamanı oluşturabilirsin.',
    whatsNextItem2Title: 'Uygulamanı Yarat',
    whatsNextItem3:
      'Uygulamana eklediğin API`ların dökümantasyonunu inceleyerek API testlerini gerçekleştirebilirsin.',
    whatsNextItem3Title: 'API`leri Test Et',
    whatsNextItem4:
      'Uygulamanı hayata geçirebilmen için ek bazı güvenlik ve sözleşme adımları işletmemiz gerekecek. Daha fazla bilgi için bize ulaşabilirsin.',
    whatsNextItem4Title: 'Uygulamanı Hayata Geçir',
    applicationTraffic: 'Uygulama Trafiği',
    quotaConsumption: 'Kota Kullanımı',
    customReports: 'Özel Raporlar',
    edit: 'Düzenle',
  },
  apis: {
    header: "API'ler",
    subHeader: 'Tüm Akbank API`larını incele ve ihtiyacına en uygun olanı bul.',
    apisButton: 'Detaylar',
  },
  usecases: {
    header: 'Kullanım Senaryoları',
    subHeader:
      'Akbank API Portal üzerinden seçeceğiniz API’lar ile uygulamalarınıza müşterilerinizin ihtiyaçlarına uygun yeni özellikler ve araçlar ekleyebilirsiniz.',
    apisButton: 'Detaylar',
    sliderTitle: "Kullanılan API'lar",
  },
  footer: {
    linkOne: 'Akbank',
    linkTwo: 'Akbank LAB',
    linkThree: 'Bize Ulaşın',
  },
  captcha: {
    reload: 'Yenile',
    text: 'LÜTFEN RESİMDEKİ KARAKTERLERİ GİRİNİZ.',
    error: 'Girdiğiniz karakterler yanlış, lütfen tekrar deneyin.',
  },
  resources: {
    apis: {
      name: 'API |||| API`ler',
      fields: {
        name: 'API Adı',
        portalStatus: 'Statü',
        accessStatus: 'Görünürlük',
        apiServiceType: 'Type',
        ssgServiceType: 'Type',
        createTs: 'Oluşturulma',
        modifyTs: 'Değİştİrİlme',
        version: 'Versiyon',
        versionShort: 'V',
        description: 'Açıklama',
        privateDescription: 'Özel Tanım',
        tags: 'Etiket',
        applicationUsage: 'Uygulamalar',
        assets: 'İçerik',
        apiLocation: 'API Lokasyonu',
        apiGroup: 'API Grubu',
      },
      portalStatus: {
        enabled: 'Aktif',
        disabled: 'Pasif',
        deprecated: 'Kaldırıldı',
        unpublished: 'Yayınlanmamış',
        incomplete: 'Tamamlanmamış',
      },
      accessStatus: {
        public: 'Public',
        private: 'Private',
      },
      last_update: {
        fields: {
          updated: 'Değiştirilme Tarihi %{date}',
        },
      },
      list: {
        cards: {
          fields: {
            updated: 'Değiştirilme Tarihi %{date}',
            version: 'v%{version}',
            applications: '%{smart_count} app(s)',
            applications_long:
              '1 application using this API |||| %{smart_count} applications using this API',
            averageLatency: '%{ms} ms',
            averageLatency_long: 'Average latency past 7 days',
          },
        },
        sort: {
          name: {
            asc: 'API adı: A-Z',
            desc: 'API adı: Z-A',
          },
          createTs: {
            asc: 'Oluşturulma Tarihi: eskiden yeniye',
            desc: 'Oluşturulma Tarihi:  yeniden eskiye',
          },
          modifyTs: {
            asc: 'Değiştirilme Tarihi: eskiden yeniye',
            desc: 'Değiştirilme Tarihi yeniden eskiye',
          },
        },
        filters: {
          search: 'API adı veya açıklama ile ara',
        },
      },
      overview: {
        title: 'Genel Bakış',
        fields: {
          version: 'v%{version}',
        },
        actions: {
          download_assets: 'İçeriği İndir',
        },
        notifications: {
          no_assets: 'Bu API ile ilişkili içerik yok.',
        },
      },
      specification: {
        title: 'Özellikler',
        fields: {
          select_application_label: 'Kullanılan uygulamalar',
        },
        actions: {
          select_application: 'Varsayılan anahtarını kullanacağın uygulamayı seç.',
          search_or_select_application: 'Ara veya Uygulama Seç',
        },
      },
      documentation: {
        title: 'Dökümantasyon',
      },
    },
    apiGroups: {
      name: 'API Group |||| API Grupları',
      short_name: 'Grup |||| Gruplar',
      fields: {
        name: 'Adı',
      },
    },
    applications: {
      name: 'Uygulama |||| Uygulamalar',
      fields: {
        name: 'Adı',
        apiKey: 'API Anahtarı:',
        keySecret: 'Paylaşılan anahtar :',
        apiKeyClientID: 'API Anahtarı / İstemci ID',
        apisIncluded: 'Dahil Edilen API`ler',
        authentication: 'Kimlik Doğrulama',
        description: 'Açıklama',
        encrypted: 'Şifreli',
        sharedSecretClientSecret: 'Paylaşılan Gizli Anahtar / İstemci Anahtarı',
        oauthType: 'OAuth Türü',
        oauthCallbackUrl: 'OAuth Geri Çağırma URL"i',
        oauthScope: 'OAuth kapsamı',
        overview: 'Genel Bakış',
        status: 'Durum',
        apiGroups: 'API Grupları',
        apiGroup: 'API Grup',
        organization: 'Organizayon',
        applicationInformation: 'Uygulama Bilgileri',
        customField: 'Özel Alanlar',
        noCustomFields: 'Kullanılabilir Özel Alan Yok',
        noApplications: 'Kullanılabilir Uygulama Yok',
        apiManagement: 'API Yönetimi',
        authCredentials: 'Kimlik Doğrulama / Kimlik Bilgileri',
        callbackUrl: 'Geri çağırma/ Yönlendirme URL"si"',
        scope: 'Kapsam',
        type: 'Tip',
        none: 'Yok',
        public: 'Herkese Açık',
        confidential: 'Gizli',
        sharedSecretFormat: 'Paylaşılan Gizli Anahtar Formatı',
        selectOrganization: 'Kuruluş Seçin',
        apiPlan: 'API Plan',
        quota: 'Kota',
        rateLimit: 'Rate Sınırı',
        termsOfUseApi: 'API Ekle"ye tıklayarak Şartlar ve Koşulları Kabul Ediyorum',
        actions: 'Aksiyonlar',
        default: 'Varsayılan',
      },
      actions: {
        generateSecret: 'Yeni Gizli Anahtar Oluştur',
        copyNewSecret: 'Yeni Gizli Anahtarı Kopyala',
        plainTextSecret: 'Düz Metin Anahtar',
        hashedSecret: 'Hashed Gizli anahtar',
        cancel: 'Kapat',
        save: 'Kaydet',
        addApplication: 'Uygulama Ekle',
        createApplication: 'Uygulama Oluştur',
        deleteApplication: 'Uygulama Sil',
        deleting_title: 'Uygulama Siliniyor',
        select_api: 'API Seç',
        addApi: 'API Ekle',
        addApiGroup: 'API Groubu Ekle',
        searchByApiTitle: 'Ara',
        filterByTag: 'Etikete göre filtrele',
        accept_terms_and_conditions: 'Şart ve koşulları kabul ediyorum',
        edit: 'Düzenle',
        delete: 'Sil',
      },
      validation: {
        error_application_name_not_unique: 'Bu uygulamanın adı benzersiz değil',
        callback_url_caption: 'Virgülle ayrılmış değer kullanın',
        scope_caption: 'Boşlukla ayrılmış değer kullanın',
        application_name_caption: 'En fazla 50 karakterlik benzersiz bir ad kullanın',
      },
      status: {
        enabled: 'Aktif',
        disabled: 'Pasif',
        deprecated: 'Kaldırıldı',
        unpublished: 'Yayınlanmamış',
        rejected: 'Reddedildi',
        application_pending_approval: 'Onay Bekliyor',
        edit_application_pending_approval: 'Onay Bekliyor',
      },
      list: {
        sort: {
          name: {
            asc: 'Uygulama Adı: A-Z',
            desc: 'Uygulama Adı: Z-A',
          },
        },
      },
      notifications: {
        configuration: 'Yapılandırma',
        copy_success: 'Panoya başarıyla kopyalandı',
        copy_error: 'Panoya kopyalanamadı',
        generate_secret_warning_1:
          'Yeni bir gizli anahtar oluşturmak, API anahtarını değiştirir ve mevcut API anahtarını geçersiz kılar.',
        generate_secret_warning_2:
          'Bu, geçerli API anahtarını kullanan herkesin erişimini keser. Bu API"leri kullanan uygulamaları kodlayan geliştiricilerle yeni oluşturulan anahtarı paylaş ve kullan',
        secret_generated_heading: 'Yeni Anahtar Oluşturuldu',
        secret_generated_heading_error: 'Gizli anahtar oluşturulurken bir hata oluştu',
        secret_generated_message:
          'Gizli anahtar metni yalnızca geçerli tarayıcı oturumu sırasında görünür olacak ve sayfa yenilendikten sonra erişilemeyecektir.',
        copy_secret_now: 'Paylaşılan gizli anahtarı şimdi kopyala',
        copy_to_clipboard: 'Panoya kopyala',
        edit_overview: 'Genel bakışı düzenle',
        empty_overview: 'Değer yok',
        create_success: 'Uygulama başarılı bir şekilde oluşturuldu.',
        create_error: 'Uygulama oluşturulurken bir hata oluştu.',
        edit_success: 'Uygulama başarılı bir şekilde güncellendi.',
        edit_error: 'Uygulama güncellenirken bir hata oluştu.',
        delete_success: 'Uygulama başarılı bir şekilde silindi.',
        delete_error: 'Uygulama silinirken bir hata oluştu.',
      },
      confirm_delete: 'Bu uygulamayı silmek üzeresin. Emin misin?',
      deleting_content:
        'Anahtarlar dağıtılıyor ve uygulama siliniyor. Bu işlem birkaç dakika sürebilir.',
    },
    userContexts: {
      title: 'Profilim',
      fields: {
        userDetails: {
          username: 'Kullanıcı Adı',
          lastName: 'Soyadı',
          firstName: 'Adı',
          email: 'Eposta',
          password: 'Şifre',
        },
        currentPassword: 'Mevcut Şifre',
        newPassword: 'Yeni Şifre',
        confirmNewPassword: 'Yeni Şifre Tekrar',
      },
      notifications: {
        profile_not_exist_error: 'Bu profil mevcut değil',
        update_success: 'Profil güncellendi',
        update_error: 'Profil güncellemesi başarısız oldu',
        invalid_password: 'Lütfen mevcut şifrenizi giriniz',
        confirm_password_change: 'Şifreniz sıfırlandı. Giriş yapmak için yeni şifrenizi kullanın.',
      },
      validation: {
        error_current_password_empty: 'Lütfen mevcut şifrenizi giriniz',
        error_password_match: 'Lütfen aynı şifreyi giriniz.',
        tooltip_password:
          'Parola gereksinimleri:\n- Minimum 8 karakter\n- Maximum 60 karakter\n-En az bir küçük harf\n- En az bir büyük harf\n- En az bir sayı\n- En az bir özel karakter: !@#$%^&*',
        tooltip_password_confirm: 'Şifre tekrar',
      },
      actions: {
        edit_profile: 'Edit My Settings',
        cancel: 'Kapat',
      },
    },
  },
  apihub: {
    login: {
      title: 'Sign In To API Hub',
      fields: {
        username: 'Username',
        password: 'Password',
      },
      actions: {
        sign_in: 'Sign in',
        sign_in_with: 'Sign in with',
        sign_up_title: 'New to API Hub?',
        sign_up: 'Create an API Hub Account',
        forgot_password: 'Forgot password?',
      },
      notifications: {
        invalid_credentials: 'Invalid credentials',
        selected_scheme: 'Signing in with',
      },
    },
    account_setup: {
      title: 'Complete And Activate Account',
      fields: {
        firstname: 'First Name',
        lastname: 'Last Name',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        confirm_password: 'Confirm Password',
      },
      actions: {
        submit: 'Activate Your Account',
        open_login_page: 'Go to Sign In',
      },
      validation: {
        error_password_match: 'The passwords do not match',
        error_username_not_unique: 'This username is not unique',
        tooltip_username: 'Minimum 6 characters\nMaximum 60 characters',
        tooltip_password:
          'Password requirements:\n- Minimum 8 characters\n- Maximum 60 characters\n- At least one lowercase character\n- At least one uppercase character bbbb\n- At least one number\n- At least one special character: !@#$%^&*',
        tooltip_password_confirm: 'Repeat your password',
      },
      notifications: {
        prepare: 'Preparing form...',
        invalid_request: 'Your account cannot be set up.',
        success: 'Your account has been successfully set up.',
      },
      terms_of_use: {
        terms_of_use_acknowledgement: 'I have read and accept the ',
        terms_of_use: 'Terms of Use',
        terms_of_use_validation: 'Accept our terms and conditions',
        terms_of_use_dialog: {
          title: 'Terms of Use',
          close: 'Close',
        },
      },
    },
    validation: {
      password: {
        at_least_one_lowercase_character: 'En az bir küçük karakter',
        at_least_one_uppercase_character: 'En az bir büyük karakter',
        at_least_one_number: 'En az bir sayı',
        at_least_one_special_character: 'En az bir özel karakter: !@#$%^&*',
      },
    },
    new_password: {
      title: 'Create New Password',
      fields: {
        current_password: 'Current Password',
        password: 'Password',
        confirm_password: 'Confirm Password',
      },
      actions: {
        change_password: 'Change password',
        open_login_page: 'Go to Sign In',
      },
      validation: {
        error_password_match: 'The passwords do not match xxxx',
        tooltip_password:
          'Password requirements:\n- Minimum 8 characters\n- Maximum 60 characters\n- At least one lowercase character\n- At least one uppercase character eee\n- At least one number\n- At least one special character: !@#$%^&*',
        tooltip_password_confirm: 'Repeat your password',
      },
      notifications: {
        confirmation: 'Your password has been reset. Use your new password to log in.',
        verifying_token: 'Verifying your password reset request...',
        invalid_token: 'Cannot create a new password: your token is invalid.',
      },
    },
  },
  ra: {
    ...raMessages,
    page: {
      dashboard: 'Ana Sayfa',
    },
    action: {
      add_filter: 'Filtre Ekle',
      show: 'Göster',
      edit: 'Düzenle',
      bulk_actions: '1 adet seçildi |||| %{smart_count} adet seçildi',
      loading: 'Yükleniyor...',
      save: 'Kaydet',
      cancel: 'İptal',
    },
    actions: {
      ...raMessages.actions,
      open_sidebar: 'Menüyü Aç',
      close_sidebar: 'Menüyü Kapat',
    },
    navigation: {
      ...raMessages.navigation,
      page_rows_per_page: 'Sayfa başına adet',
      next: 'Sonraki',
      prev: 'Önceki',
    },
    auth: {
      logout: 'Çıkış',
    },
    validation: {
      minLength: 'En az 8 karakter giriniz',
      maxLength: 'En fazla 60 karakter giriniz',
    },
  },
});
