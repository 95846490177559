import React from 'react';
import { useTranslate } from 'ra-core';
import {
  makeStyles,
  Typography,
  Container,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useCaseList from '../../files/use_cases_list.json';
import Icon from '@bappweb/icon';
import { useLocale } from 'ra-core';

export const UseCase = () => {
  const t = useTranslate();
  const classes = useStyles();
  const { ENVIRONMENT } = global.APIHUB_CONFIG;
  const locale = useLocale();

  const detectContent =
    locale === 'en' ? useCaseList[ENVIRONMENT].UseCasesEN : useCaseList[ENVIRONMENT].UseCasesTR;

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<Icon name="NavigationRight" size={22} />}
          className={classes.breadcrumps}
        >
          <Link color="inherit" to="/" component={RouterLink}>
            {t('common.home')}
          </Link>
          <Typography color="textPrimary"> {t('home.useCasesHeader')}</Typography>
        </Breadcrumbs>
        <div className={classes.header}>
          <Typography variant="h2" className={classes.mainHeader}>
            {t('usecases.header')}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.mainHeaderSubtitle} color="textSecondary">
                {t('usecases.subHeader')}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.apiGrid}>
          <Grid container>
            {useCaseList &&
              detectContent.map((card, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={'api_list_' + card.uuid + index}>
                    <Card className={classes.cardContainer}>
                      <CardMedia component="img" height="180" image={card.url} />
                      <CardContent>
                        <Typography className={classes.cardTitle} variant="h6">
                          {card.name}
                        </Typography>
                        <Typography className={classes.cardDescription} color="textSecondary">
                          {card.description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          to={`/use-case-detail/${card.uuid}`}
                          className={classes.cardButton}
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                        >
                          {t('home.useCaseDetailButton')}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '100px',
  },
  header: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: '40px',
  },
  mainHeader: {
    marginTop: '60px',
    flexBasis: '10%',
    marginBottom: '20px',
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  mainHeaderSubtitle: {
    marginBottom: '40px',
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cardContainer: {
    maxWidth: '95%',
    minHeight: '500px',
    margin: '0 10px',
    backgroundColor: 'white',
    marginBottom: '25px',
    [theme.breakpoints.only('md')]: {
      minHeight: '550px',
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: '450px',
    },
    [theme.breakpoints.only('lg')]: {
      minHeight: '500px',
    },
  },
  cardTitle: {
    marginTop: '30px',
    marginBottom: '12px',
    minHeight: '50px',
  },
  cardDescription: {
    minHeight: '150px',
    [theme.breakpoints.only('sm')]: {
      minHeight: '120px',
    },
  },
  cardButton: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
  detailImage: {
    maxWidth: '100%',
    borderRadius: 5,
  },
  breadcrumps: {
    height: 56,
    marginBottom: 40,
    marginTop: 20,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 10,
    },
  },
  section: {
    backgroundColor: 'white',
    paddingTop: 88,
    paddingBottom: 96,
  },
  mTop: {
    marginTop: '40px',
  },
}));
