import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const NavLink = ({ className, ...props }) => {
  const classes = useStyles();
  return <Link {...props} className={classnames(classes.navLink, className)} />;
};

const useStyles = makeStyles(theme => ({
  navLink: {
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    outline: 'none',
    fontFamily: 'Campton, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'none',
    padding: '0 24px',
  },
}));

export default NavLink;
