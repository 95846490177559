import React from 'react';
import { UserContextEdit as DefaultUserContextEdit } from 'layer7-apihub';
import { makeStyles, Container, Grid } from '@material-ui/core';

export const UserContextEdit = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid className={classes.form}>
          <DefaultUserContextEdit {...props} />
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '100px',
    padding: 0,
    '& .MuiPaper-root': {
      maxWidth: '100%',
      flexBasis: '100%',
      backgroundColor: '#fff',
    },
    '& .MuiTypography-subtitle1': {
      color: '#000',
      fontSize: '1rem',
    },
  },
  form: {
    '&.MuiGrid-root h2': {
      color: '#000',
      fontSize: '2rem',
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
