import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DataLayer from '../../core/Ga_dataLayer';
import { useAuthState } from 'ra-core';

const ApiCard = ({ uuid, name, description, even, buttonName = 'Details', fullWidth = false }) => {
  const classes = useStyles({ even });
  const { authenticated } = useAuthState();
  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Typography variant="h6" className={classes.title}>
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary" className={classes.content}>
          {description}
        </Typography>
        <Button
          className={classes.button}
          fullWidth={fullWidth}
          variant="outlined"
          color="primary"
          to={uuid !== '' ? '/apis/' + uuid.toLowerCase() + '/show' : '/api-list/'}
          component={Link}
          // eslint-disable-next-line no-restricted-globals
          onClick={() =>
            DataLayer(
              'button_click',
              name,
              'body apis slider',
              '',
              !authenticated ? 'login' : `${'apis/' + uuid.toLowerCase() + '/show'}`
            )
          }
        >
          {buttonName}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: '8px',
  },
  contentContainer: {
    height: '180px',
    margin: '0 12px',
    borderRadius: 8,
    backgroundColor: ({ even }) =>
      even ? theme.palette.grey['500'] : theme.palette.background.paper,
    padding: 20,
    paddingBottom: 24,
  },
  content: {
    marginBottom: 24,
    height: 85,
    fontSize: theme.typography.pxToRem(18),
  },
  button: {
    position: 'absolute',
    bottom: '20px',
  },
}));

export default ApiCard;
