import { mergeTranslations } from 'react-admin';
import raMessages from 'ra-language-english';
import { englishMessages } from 'layer7-apihub';

export default mergeTranslations(englishMessages, {
  metaTags: {
    description:
      'Develop the future right now! Bring your ideas to life with the Akbank API portal.',
  },
  example: {
    action: {
      toggle_dark_mode: 'Toggle dark mode',
    },
  },
  common: {
    home: 'Home',
  },
  header: {
    home: 'Home',
    api: "API's",
    use_case: 'Use Cases',
    faq: 'FAQ',
    login_signup: 'Login / Sign up',
    myAccount: 'My Account',
    accountMenu: {
      dashboard: 'My Dashboard',
      myProfile: 'Settings',
      logout: 'Logout',
    },
  },
  login: {
    page_motto: 'Development for everyone',
    login_title: 'Sign in to API Portal',
    invalid_credentials: 'Invalid credentials',
    form: {
      username: 'USERNAME',
      username_placeholder: 'Enter Here',
      password: 'PASSWORD',
      password_placeholder: 'Enter Here',
      submit: 'Sign In',
      forgot_password: 'Forgot Password?',
      or: 'Or',
      sign_up: 'Sign Up Now',
    },
  },
  register: {
    title: 'Sign Up to API Portal',
    form: {
      email: 'EMAIL',
      email_placeholder: 'Enter Here',
      confirm_email: 'CONFIRM EMAIL',
      confirm_email_placeholder: 'Enter Here',
      organization: 'ORGANIZATION',
      organization_placeholder: 'Enter Here',
      organization_description: 'ORGANIZATION DESCRIPTION',
      organization_description_placeholder: 'Enter Here',
      signup: 'Sign Up',
    },
    registerSuccess: 'Your registration has been successful',
    registerError: 'An error occured while processing your request',
    confirmation_title: 'Check Your Email',
    confirmation:
      'Registration received. A notification email will be sent to the address provided.',
    return_to_homepage: 'Return to homepage',
  },
  resetPassword: {
    title: 'Reset Password',
    infoTitle: 'Enter your email',
    info: 'We will email a link to reset your password.',
    form: {
      email: 'EMAIL',
      username: 'USERNAME',
      username_placeholder: 'Enter Here',
      email_placeholder: 'Enter Here',
      resetPassword: 'Reset Password',
    },
    checkYourEmailTitle: 'Check your email',
    checkYourEmailDescription: 'Click the link in your email to reset your password.',
    login: 'Login',
  },
  validation: {
    required: 'This field is required',
    checkEmail: 'Provide a valid e-mail',
  },
  home: {
    heroHeader: 'Development for everyone',
    heroSubHeader:
      'Build and extend your products and services with our intuitive and secure access interfaces.',
    getStartedButton: 'Get Started',
    howItWorksButton: 'How it Works',
    bigApiCardHeader: 'What Can You Do?',
    ourApisHeader: 'Our API’s',
    useCasesHeader: 'Use Cases',
    ourApisSubHeader:
      'You can add new features to your platforms with the APIs you will integrate through Akbank API Portal.',
    useCaseDetailButton: 'Details',
    seeMoreButton: 'See More',
    learnButtonMutton: 'Learn More',
    whatCanYouDoItems: {
      item1Text: 'Exchange Rates',
      item1Description: 'With this API you can reach the currency rate list for the chosen date.',
      item2Text: 'Credit Interest Rates',
      item2Description:
        'With this API you can see the current Akbank interest rates for Consumer Loans, Mortgages and Car Loans.',
      item3Text: 'Credit Payment Plan',
      item3Description:
        'With this API you can calculate and see the payment plan for the chosen credit interest and term.',
    },
    sliderButton: 'Details',
  },
  faq: {
    header: 'FAQ',
    subHeader: 'Here you can find what you want to know about API’s',
    breadcrumbText: 'FAQ',
    accordion: {
      titleOne: 'Can I use APIs for commercial purposes?',
      descOne:
        'Yes, APIs can be used for commercial purposes once a commercial agreement, which will include terms of use, has been reached with Akbank.',
      titleTwo: 'How do I get started?',
      descTwo: {
        title: `To start developing using the official APIs, follow the steps below:`,
        ruleOne: `1. Sign In or Register a new account.`,
        ruleTwo: `2. Setup an application to receive an API Key.`,
        ruleThree: `3. Develop your app using your API Key for authentication.`,
      },
      titleThree: 'What do I need to begin development using the Remote APIs?',
      descThree: {
        title: `A developer needs to follow these steps for developing applications:`,
        ruleOne: `1. Register as a developer.`,
        ruleTwo: `2. Read through the available API documentation and analyze the features.`,
        ruleThree: `3. Set up an application to receive a developer key and secret.`,
        ruleFour: `4. Build an application and receive authentication via OAuth using the provided developer key and secret. An access token will be returned.`,
        ruleFive: `5. Use the access token to receive authorization and invoke APIs.`,
      },
      titleFour:
        'Is there a limit to the number of Remote API calls I can make during development?',
      descFour: 'Yes, sandbox APIs are currently restricted to a limit of 5 requests per second.',
      titleFive: 'What happens once I finish my development?',
      descFive: {
        title:
          'When you decide you are ready to submit your application for approval, the high level steps to follow are listed below:',
        ruleOne: '1. Notify Akbank',
        ruleTwo: '2. Akbank will review your application',
        ruleThree: '3. Formal and technical review sessions are scheduled',
        ruleFour: '4. The application is released on a marketplace',
      },
      titleSix: 'Where can I find documentation for development?',
      descSix: 'To find specific documentation, visit our Documentation page.',
    },
  },
  howItWorks: {
    header: 'How to use Akbank API Portal',
    breadcrumbText: 'How it works',
    step: 'Step',
    steps: {
      step1Text: 'Open An Account',
      step1Description: 'Easily open your account by clicking get started or sign up.',
      step2Text: 'Create Your Application',
      step2Description: 'Build your app using one or more APIs.',
      step3Text: 'Test Your API’s',
      step3Description:
        'Review the documentation of the APIs you add to your applications and perform API tests.',
      step4Text: 'Launch Your Application',
      step4Description:
        'Complete the security and contracting steps to go live with your application. You can reach us for more info.',
    },
  },
  applicationStatus: {
    enabled: 'Enabled',
    disabled: 'Disabled',
    unpublished: 'Unpublished',
    applicationPendingApproval: 'Pending Approval',
    editApplicationPendingApproval: 'Edit Pending Approval',
    deprecated: 'Deprecated',
    rejected: 'Rejected',
  },
  dashboard: {
    breakcrumbText: 'My Dashboard',
    header: 'Welcome to your Dashboard!',
    subHeader:
      'You can create new projects to access APIs and associated credentials, reference material, etc.',
    monitorYourApplication: 'Monitor Your Application',
    addNewApplication: 'Add New Application',
    addYourFirstApplication: 'Add Your First Application',
    noProjectText: 'You have no project',
    noProjectPermissionError: 'You don’t have permission to create an application',
    createYourFirstApplicationText: 'Create your first application',
    myApplications: 'My Applications',
    gridApplicationNameColumnHeader: 'Application Name',
    gridStatusColumnHeader: 'Status',
    gridActionsHeader: 'Actions',
    whatsNextHeader: 'What is the next?',
    whatsNextSubHeader: 'Follow these steps to start using Akbank API’s',
    unsureTextHeader: 'Unsure what to do next?',
    unsureTextSubHeader: 'Follow these steps to start using Akbank API’s',
    whatsNextItem1: 'Easily open your account by clicking get started or sign up.',
    whatsNextItem1Title: 'Open An Account',
    whatsNextItem2: 'Build your app using one or more APIs.',
    whatsNextItem2Title: 'Create Your Application',
    whatsNextItem3:
      'Review the documentation of the APIs you add to your applications and perform API tests.',
    whatsNextItem3Title: 'Test Your APIs',
    whatsNextItem4:
      'Complete the security and contracting steps to go live with your application. You can reach us for more info.',
    whatsNextItem4Title: 'Launch Your Application',
    applicationTraffic: 'Application Traffic',
    quotaConsumption: 'Quota Consumption',
    customReports: 'Custom Report',
    edit: 'Edit',
  },
  apis: {
    header: "API's",
    subHeader:
      'Browse through the entire range of Akbank API’s and find the one that best suits your needs.',
    apisButton: 'Details',
  },
  usecases: {
    header: 'Uses Cases',
    subHeader:
      'You can add new features to your platforms with the APIs you will integrate through Akbank API Portal.',
    apisButton: 'Detaylar',
    sliderTitle: "API's",
  },
  footer: {
    linkOne: 'Akbank',
    linkTwo: 'Akbank LAB',
    linkThree: 'Contact Us',
  },
  captcha: {
    reload: 'Reload',
    text: 'Please enter characters above',
    error: 'Invalid captcha value, please try again',
  },
  resources: {
    apis: {
      name: 'API |||| APIs',
      fields: {
        name: 'Name',
        portalStatus: 'State',
        accessStatus: 'Visibility',
        apiServiceType: 'Type',
        ssgServiceType: 'Type',
        createTs: 'Created',
        modifyTs: 'Modified',
        version: 'Version',
        versionShort: 'V',
        description: 'Description',
        privateDescription: 'Private Description',
        tags: 'Tags',
        applicationUsage: 'Applications',
        assets: 'Assets',
        apiLocation: 'API Location',
        apiGroup: 'API Group',
      },
      portalStatus: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        deprecated: 'Deprecated',
        unpublished: 'Unpublished',
        incomplete: 'Incomplete',
      },
      accessStatus: {
        public: 'Public',
        private: 'Private',
      },
      last_update: {
        fields: {
          updated: 'Modified %{date}',
        },
      },
      list: {
        cards: {
          fields: {
            updated: 'Modified %{date}',
            version: 'v%{version}',
            applications: '%{smart_count} app(s)',
            applications_long:
              '1 application using this API |||| %{smart_count} applications using this API',
            averageLatency: '%{ms} ms',
            averageLatency_long: 'Average latency past 7 days',
          },
        },
        sort: {
          name: {
            asc: 'API name: A-Z',
            desc: 'API name: Z-A',
          },
          createTs: {
            asc: 'Date created: old to new',
            desc: 'Date created: new to old',
          },
          modifyTs: {
            asc: 'Date modified: old to new',
            desc: 'Date modified: new to old',
          },
        },
        filters: {
          search: 'Search by name or description',
        },
      },
      overview: {
        title: 'Overview',
        fields: {
          version: 'v%{version}',
        },
        actions: {
          download_assets: 'Download Assets',
        },
        notifications: {
          no_assets: 'No assets associated with this API.',
        },
      },
      specification: {
        title: 'Specs',
        fields: {
          select_application_label: 'Apps being used',
        },
        actions: {
          select_application: 'Select application to use its default key.',
          search_or_select_application: 'Search or Select Application',
        },
      },
      documentation: {
        title: 'Documentation',
      },
    },
    applications: {
      name: 'Application |||| Applications',
      fields: {
        name: 'Name',
        apiKey: 'API Key:',
        keySecret: 'Shared Secret:',
        apiKeyClientID: 'API Key / Client ID',
        apisIncluded: 'APIs Included',
        authentication: 'Authentication',
        description: 'Description',
        encrypted: 'Encrypted',
        sharedSecretClientSecret: 'Shared Secret / Client Secret',
        oauthType: 'OAuth Type',
        oauthCallbackUrl: 'OAuth Callback URL',
        oauthScope: 'OAuth Scope',
        overview: 'Overview',
        status: 'State',
        apiGroups: 'API Groups',
        apiGroup: 'API Group',
        organization: 'Organization',
        applicationInformation: 'Application Information',
        customField: 'Custom Fields',
        noCustomFields: 'No available custom fields',
        noApplications: 'No available applications',
        apiManagement: 'API Management',
        authCredentials: 'Authentication and Credentials',
        callbackUrl: 'Callback/Redirect URL(s)',
        scope: 'Scope',
        type: 'Tip',
        none: 'None',
        public: 'Public',
        confidential: 'Confidential',
        sharedSecretFormat: 'Shared Secret Format',
        selectOrganization: 'Select organization',
        apiPlan: 'API Plan',
        quota: 'Quota',
        rateLimit: 'Rate Limit',
        termsOfUseApi: 'By clicking Add API, I accept Terms and Conditions',
        actions: 'Actions',
        default: 'Default',
      },
      actions: {
        generateSecret: 'Generate New Secret',
        copyNewSecret: 'Copy New Secret',
        plainTextSecret: 'Plain text secret',
        hashedSecret: 'Hashed secret',
        cancel: 'Cancel',
        save: 'Save',
        addApplication: 'Add Application',
        createApplication: 'Create Application',
        deleteApplication: 'Delete Application',
        deleting_title: 'Deleting Application',
        select_api: 'Select API',
        addApi: 'Add API',
        addApiGroup: 'Add API Group',
        searchByApiTitle: 'Search',
        filterByTag: 'Filter by tag',
        accept_terms_and_conditions: 'I Accept the Terms & Conditions',
        edit: 'Edit',
        delete: 'Delete',
      },
      validation: {
        error_application_name_not_unique: 'This application name is not unique',
        callback_url_caption: 'Use comma separated values',
        scope_caption: 'Use space separated values',
        application_name_caption: 'Use unique name 50 characters (max)',
      },
      status: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        deprecated: 'Deprecated',
        unpublished: 'Unpublished',
        rejected: 'Rejected',
        application_pending_approval: 'Pending Approval',
        edit_application_pending_approval: 'Pending Approval',
      },
      list: {
        sort: {
          name: {
            asc: 'Application Name: A-Z',
            desc: 'Application Name: Z-A',
          },
        },
      },
      notifications: {
        configuration: 'Configuration',
        copy_success: 'Copied to Clipboard successfully',
        copy_error: 'Copy to Clipboard failed',
        generate_secret_warning_1:
          'Generating a new secret changes the API key and voids the current API key.',
        generate_secret_warning_2:
          'This breaks access for anyone using the current API key. Share and use the newly generated secret with developers coding their application that uses the APIs.',
        secret_generated_heading: 'New Secret Generated',
        secret_generated_heading_error: 'An error occurred while generating the secret',
        secret_generated_message:
          'Text secret will only be visible during the current browser session and will be hashed after the page has been refreshed.',
        copy_secret_now: 'Copy the shared secret now',
        copy_to_clipboard: 'Copy to Clipboard',
        edit_overview: 'Edit overview',
        empty_overview: 'No value',
        create_success: 'Application successfully created.',
        create_error: 'An error occurred while creating the application.',
        edit_success: 'Application successfully updated.',
        edit_error: 'An error occurred while updating the application.',
        delete_success: 'Application successfully deleted.',
        delete_error: 'An error occurred while deleting the application.',
      },
      confirm_delete: 'You are about to delete this application. Are you sure?',
      deleting_content: 'Undeploying keys and deleting application. This may take several minutes.',
    },
    userContexts: {
      title: 'My Settings',
      fields: {
        userDetails: {
          username: 'Username',
          lastName: 'Last Name',
          firstName: 'First Name',
          email: 'Email',
          password: 'Password',
        },
        currentPassword: 'Current Password',
        newPassword: 'Password',
        confirmNewPassword: 'Confirm Password',
      },
      notifications: {
        profile_not_exist_error: 'This profile does not exist',
        update_success: 'Profile updated',
        update_error: 'The profile update has failed',
        invalid_password: 'The current password in invalid',
        confirm_password_change: 'Your password has been reset. Use your new password to log in.',
      },
      validation: {
        error_current_password_empty: 'Please enter your current password',
        error_password_match: 'The passwords do not match',
        tooltip_password:
          'Password requirements:\n- Minimum 8 characters\n- Maximum 60 characters\n- At least one lowercase character\n- At least one uppercase character\n- At least one number\n- At least one special character: !@#$%^&*',
        tooltip_password_confirm: 'Repeat your password',
      },
    },
  },
  ra: {
    ...raMessages,
    page: {
      dashboard: 'Home',
    },
    action: {
      add_filter: 'Add Filter',
      show: 'Show',
      edit: 'Edit',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      loading: 'Loading...',
    },
    actions: {
      ...raMessages.actions,
      open_sidebar: 'Open the menu',
      close_sidebar: 'Close the menu',
    },
    navigation: {
      ...raMessages.navigation,
      page_rows_per_page: 'Items per page:',
      next: 'Next',
      prev: 'Prev',
    },
    auth: {
      logout: 'Logout',
    },
  },
});
