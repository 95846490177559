import React from 'react';
import { Show } from 'layer7-apihub';
import { makeStyles, Container } from '@material-ui/core';

import { ApplicationTitle } from './ApplicationTitle';
import { ApplicationDetails } from './ApplicationDetails';

export const ApplicationShow = props => {
  const { root: rootClassName, ...classes } = useStyles(props);
  const { permissions, id, ...rest } = props;

  return (
    <Container>
      <Show
        className={rootClassName}
        classes={classes}
        title={<ApplicationTitle />}
        id={id}
        {...rest}
      >
        <ApplicationDetails />
      </Show>
    </Container>
  );
};

const useStyles = makeStyles(
  {
    root: {
      marginBottom: '100px',
    },
    card: {},
  },
  {
    name: 'HealthcareApplicationShow',
  }
);
