import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import CompanyApiPortalSvg from '../ui/icons/CompanyApiPortalSvg';
import Image from '@bappweb/image';

export const SideContent = () => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <>
      <CompanyApiPortalSvg className={classes.logo} />
      <Typography variant="h2" className={classes.motto}>
        {t('login.page_motto')}
      </Typography>
      <Image
        style={{ maxHeight: 350 }}
        path={'/img/svg/hero-white.svg'}
        fallbackText={'Hero image'}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {},
  },
  logo: {
    paddingTop: 48,
    paddingBottom: 32,
  },
  motto: {
    fontFamily: 'Campton, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: 48,
    color: 'white',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: 'normal',
    margin: '0 0 48px',
  },
}));
