import React from 'react';
import SlickSlider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Image from '@bappweb/image';

export const sliderWidth = 1263;

const Slider = ({ children = [], slidesToShow = 1 }) => {
  const classes = useStyles();

  const settings = {
    dots: true,
    slidesToShow,
    slidesToScroll: slidesToShow,
    infinite: false,
    nextArrow: <Image path={'/img/svg/nextArrow.svg'} />,
    prevArrow: <Image path={'/img/svg/prevArrow.svg'} />,
    centerPadding: 24,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className={classes.container}>
      <SlickSlider {...settings} className={classes.slider}>
        {children}
      </SlickSlider>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 60,
  },
  slider: {
    '& .slick-track': {
      margin: 0,
    },
    '& .slick-dots': {
      bottom: -40,
      [theme.breakpoints.only('xs')]: {
        position: 'static',
        marginTop: 20,
      },
      '& li': {
        margin: 0,
        '&.slick-active button': {
          outline: 'none',
          '&:before': {
            color: theme.palette.primary.main,
            opacity: 1,
          },
        },
        '& button': {
          '&:focus': {
            '&:before': {
              color: theme.palette.primary.main,
            },
          },
          '&:hover': {
            '&:before': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    '& .slick-arrow': {
      '&:before': {
        content: 'none',
      },
      width: 24,
      height: 24,
      padding: 16,
      backgroundColor: 'white',
      zIndex: 5,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.18)',
      borderRadius: '50%',
      '&.slick-disabled': {
        display: 'none !important',
      },
      '&.slick-next': {
        right: -16,
      },
      '&.slick-prev': {
        left: -16,
      },
    },
  },
  itemContanier: {},
}));

export default Slider;
