import React from 'react';
import { makeStyles } from '@material-ui/core';
import CompanyApiPortalSvg from '../ui/icons/CompanyApiPortalSvg';
import { useTranslate } from 'ra-core';
import DataLayer from '../core/Ga_dataLayer';

export const Footer = () => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <div className={classes.root}>
      <div className={classes.footerWrapper}>
        <CompanyApiPortalSvg className={classes.logo} />
        <div className={classes.divider} />
        <div className={classes.linkWrapper}>
          <a
            className={classes.navLink}
            href="http://www.akbank.com/"
            target="_blank"
            rel="noreferrer"
          >
            {t('footer.linkOne')}
          </a>
          <a
            className={classes.navLink}
            href="https://www.akbanklab.com/tr/ana-sayfa"
            rel="noreferrer"
            target="_blank"
          >
            {t('footer.linkTwo')}
          </a>
          <a
            className={classes.navLink}
            href="mailto:apiportal@akbank.com"
            rel="noreferrer"
            target="_blank"
            onClick={() =>
              DataLayer(
                'button_click',
                'Mail Link',
                'footer',
                window.location.hash,
                'mailto:apiportal@akbank.com'
              )
            }
          >
            {t('footer.linkThree')}
          </a>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.primary.contrastText,
    width: '1px',
    height: '48px',
    marginLeft: '32px',
    marginRight: '32px',
  },
  logo: {
    height: '48px',
  },
  linkWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  navLink: {
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    outline: 'none',
    fontFamily: 'Campton, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'none',
    padding: '0 24px',
  },
  footerWrapper: {
    marginLeft: '5%',
    marginRight: '5%',
    display: 'flex !important',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 0,
  },
  root: {
    height: '120px',
    minHeight: '120px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.04)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));
