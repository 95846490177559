import React, { useEffect } from 'react';
import { useTranslate, useLogout } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Hidden,
  Drawer,
  Container,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import LightModeIcon from '@material-ui/icons/Brightness7';
import DarkModeIcon from '@material-ui/icons/Brightness4';
import { useLocation } from 'react-router-dom';
import CompanySvg from '../ui/icons/CompanySvg';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  ApiHubUserMenu,
  ApiHubLanguageSwitcher,
  SidebarButton,
  useApiHubPreference,
} from 'layer7-apihub';
import { changeTheme } from '../theme';
import NavLink from '../components/NavLink';
import Icon from '@bappweb/icon';
import { CurrentUserId } from '../core/Constants';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import DataLayer from '../core/Ga_dataLayer';

export const useResourceListLocation = () => {
  const location = useLocation();
  const RESOURCE_LIST_MATCH = /\/[^/]*/;
  const match = location.pathname.match(RESOURCE_LIST_MATCH);
  return match[0];
};

const StyledMenu = withStyles({
  paper: {
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.16);',
    marginTop: '5px',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      background: '#f5f4f6',
    },
    '& span': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))(props => <MenuItem {...props} />);

export const UnAuthenticatedAppBar = ({
  children,
  classes: classesOverride,
  className,
  languagesMenu,
  logout,
  open,
  sidebarButton,
  title,
  userMenu,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const logoutUse = useLogout();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <StyledMenuItem component={RouterLink} to={'/dashboard'} onClick={handleClose}>
            <ListItemText primary={t('header.accountMenu.dashboard')} />
          </StyledMenuItem>
          <StyledMenuItem
            component={RouterLink}
            to={`/userContexts/${CurrentUserId}/show`}
            onClick={handleClose}
          >
            <ListItemText primary={t('header.accountMenu.myProfile')} />
          </StyledMenuItem>
          <StyledMenuItem component={'a'} onClick={logoutUse}>
            <ListItemText primary={t('header.accountMenu.logout')} />
          </StyledMenuItem>
        </StyledMenu>
        <div className={classes.leftNav}>
          <NavLink exact to="/">
            {t('header.home')}
          </NavLink>
          <NavLink
            exact
            to="/api-list"
            onClick={() =>
              DataLayer(
                'button_click',
                'Apis page Not Auth',
                'header',
                window.location.hash,
                'api-list'
              )
            }
          >
            {t('header.api')}
          </NavLink>
          <NavLink exact to="/use-cases">
            {t('header.use_case')}
          </NavLink>
          <NavLink exact to="/faq">
            {t('header.faq')}
          </NavLink>
        </div>
        <div>
          <NavLink exact to="/login" className={classes.login}>
            {t('header.login_signup')}
          </NavLink>
          <LanguageSwitcher />
        </div>
      </Hidden>
      <Hidden smUp>
        <div className={classes.navigationMenuIconContainer}>
          <div className={classes.navigationMenuIcon}>
            <a onClick={toggleDrawer(true)}>
              <Icon name="NavigationMenu" />
            </a>
          </div>
        </div>
        <Drawer anchor={'top'} open={drawerOpen} onClose={toggleDrawer(false)}>
          <div className={classes.mobileNavMenuHeader}>
            <Container className={classes.mobileNavMenuHeaderContainer}>
              <CompanySvg />
              <div className={classes.mobileNavMenuHeaderContainerButtons}>
                <a onClick={toggleDrawer(false)}>
                  <Icon name="NavigationClose" />
                </a>
              </div>
            </Container>
          </div>

          <div className={classes.responsiveMenu}>
            <ul>
              <li>
                <NavLink exact to="/" onClick={toggleDrawer(false)}>
                  {t('header.home')}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/api-list" onClick={toggleDrawer(false)}>
                  {t('header.api')}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/use-cases" onClick={toggleDrawer(false)}>
                  {t('header.use_case')}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/faq" onClick={toggleDrawer(false)}>
                  {t('header.faq')}
                </NavLink>
              </li>
              <li>
                <div className={classes.forlanguage}>
                  <NavLink
                    exact
                    to="/login"
                    className={classes.login}
                    onClick={toggleDrawer(false)}
                  >
                    {t('header.login_signup')}
                  </NavLink>
                  <span>|</span>
                  <LanguageSwitcher />
                </div>
              </li>
            </ul>
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
};

UnAuthenticatedAppBar.defaultProps = {
  userMenu: <ApiHubUserMenu />,
  languagesMenu: <ApiHubLanguageSwitcher />,
  sidebarButton: <SidebarButton />,
};

const useStyles = makeStyles(theme => ({
  navigationMenuIconContainer: {
    marginLeft: 'auto',
  },
  login: {
    alignSelf: 'center',
    color: '#fff',
  },
  leftNav: {
    alignSelf: 'center',
  },
  navigationMenuIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  mobileNavMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    backgroundColor: theme.palette.background.default,
    '& a': {
      color: '#fff',
    },
  },
  mobileNavMenuHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileNavMenuHeaderContainerButtons: {
    textAlign: 'right',
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  responsiveMenu: {
    height: '350px',
    backgroundColor: theme.palette.background.default,
    justifyContent: 'end',
    boxSizing: 'border-box',
    top: '60px',
    '& > ul ': {
      listStyle: 'none',
      padding: 0,
    },
    '& > ul li': {
      height: '56px',
      width: '100%',
    },
    '& > ul > li > a': {
      padding: 0,
      paddingLeft: '20px',
      height: '56px',
      display: 'flex',
    },
    '& > ul li:last-child': {
      position: 'absolute',
      bottom: 0,
    },
  },
  userIntialsBox: {
    backgroundColor: '#fff',
    color: '#dc0005',
    borderRadius: '50%',
    marginRight: 5,
    padding: '5px',
    height: '21px',
  },
  toolbar: {
    paddingRight: 24,
    backgroundColor: theme.palette.customHeader?.main,
    color: theme.palette.getContrastText(
      theme.palette.customHeader?.main || theme.palette.common.white
    ),
  },
  header: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: '156px',
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: theme.palette.primary.contrastText,
    height: 'auto',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  logo: {
    height: theme.spacing(6),
    padding: '10px',
  },
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  forlanguage: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    color: '#fff',
  },
}));

export const ThemeModeButton = () => {
  const classes = useThemeModeButtonStyles();
  const translate = useTranslate();

  const dispatch = useDispatch();
  const themeMode = useSelector(state => state.theme);
  const [themeModePreference, setThemeModePreference] = useApiHubPreference('themeMode');

  useEffect(() => {
    if (themeModePreference && themeModePreference !== themeMode) {
      dispatch(changeTheme(themeModePreference));
    }
  }, [themeModePreference, themeMode, dispatch]);

  const handleClick = () => {
    const newTheme = themeMode === 'light' ? 'dark' : 'light';
    setThemeModePreference(newTheme);
  };

  return (
    <Tooltip title={translate('example.action.toggle_dark_mode')}>
      <IconButton color="default" onClick={handleClick}>
        {themeMode === 'light' ? (
          <DarkModeIcon className={classes.icon} />
        ) : (
          <LightModeIcon className={classes.icon} />
        )}
      </IconButton>
    </Tooltip>
  );
};

const useThemeModeButtonStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.common.white,
  },
}));
