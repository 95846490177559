import React from 'react';

import { AuthenticationLayout } from './AuthenticationLayout';
import ResetPasswordForm from './ResetPasswordForm';

export const ResetPasswordPage = () => {
  return (
    <AuthenticationLayout>
      <ResetPasswordForm />
    </AuthenticationLayout>
  );
};
