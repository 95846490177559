import React from 'react';
import { ApiShow as DefaultApiShow } from 'layer7-apihub';
import { makeStyles, Container, Grid } from '@material-ui/core';

export const ApiShow = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid className={classes.form}>
          <DefaultApiShow {...props} />
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '100px',
    padding: 0,
    '& .MuiPaper-root': {
      maxWidth: '100%',
      flexBasis: '100%',
      backgroundColor: '#fff',
    },
  },
  form: {
    '&.MuiGrid-root h2': {
      color: '#000',
      fontSize: '2rem',
      backgroundColor: theme.palette.background.paper,
    },
  },
  searchInput: {
    minWidth: '300px',
  },
}));
