import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import {
  Link,
  makeStyles,
  Typography,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Breadcrumbs,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@bappweb/icon';
import { Link as RouterLink } from 'react-router-dom';

const ExpansionPanelCustom = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #e6e5e9',
    boxShadow: 'none',
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
})(ExpansionPanel);

const ExpansionPanelSummaryCustom = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 80,
    '&$expanded': {
      minHeight: 80,
      borderBottom: 'none',
      '&:first-child': {
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      },
    },
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    order: -1,
    marginRight: '18px',
  },
})(ExpansionPanelSummary);

const ExpansionPanelDetailsCustom = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    margin: '0 50px 48px',
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
}))(ExpansionPanelDetails);

export const Faq = () => {
  const t = useTranslate();
  const classes = useStyles();

  const [expanded, setExpanded] = useState('panel1');
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<Icon name="NavigationRight" size={22} />}
          className={classes.breadcrumps}
        >
          <Link color="inherit" to="/" component={RouterLink}>
            {t('common.home')}
          </Link>
          <Typography color="textPrimary"> {t('faq.breadcrumbText')}</Typography>
        </Breadcrumbs>
        <div className={classes.section}>
          <Typography variant="h2" className={classes.mainHeader}>
            {t('faq.header')}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {t('faq.subHeader')}
          </Typography>
          <div className={classes.accordion}>
            <ExpansionPanelCustom
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <ExpansionPanelSummaryCustom
                expandIcon={
                  expanded === 'panel1' ? (
                    <Icon name="ActionsControlsMinus" />
                  ) : (
                    <Icon name="ActionsControlsAdd" />
                  )
                }
              >
                <Typography variant="h6" className={classes.accordionHeading}>
                  {t('faq.accordion.titleOne')}
                </Typography>
              </ExpansionPanelSummaryCustom>
              <ExpansionPanelDetailsCustom className={classes.accordionContent}>
                <Typography> {t('faq.accordion.descOne')} </Typography>
              </ExpansionPanelDetailsCustom>
            </ExpansionPanelCustom>
            <ExpansionPanelCustom
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <ExpansionPanelSummaryCustom
                expandIcon={
                  expanded === 'panel2' ? (
                    <Icon name="ActionsControlsMinus" />
                  ) : (
                    <Icon name="ActionsControlsAdd" />
                  )
                }
              >
                <Typography
                  variant="h6"
                  data-i18n="[html]content.body"
                  className={classes.accordionHeading}
                >
                  {t('faq.accordion.titleTwo')}
                </Typography>
              </ExpansionPanelSummaryCustom>
              <ExpansionPanelDetailsCustom className={classes.accordionContent}>
                <Typography>
                  {t('faq.accordion.descTwo.title')}
                  <br />
                  <br />
                  {t('faq.accordion.descTwo.ruleOne')}
                  <br />
                  {t('faq.accordion.descTwo.ruleTwo')}
                  <br />
                  {t('faq.accordion.descTwo.ruleThree')}
                </Typography>
              </ExpansionPanelDetailsCustom>
            </ExpansionPanelCustom>
            <ExpansionPanelCustom
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <ExpansionPanelSummaryCustom
                expandIcon={
                  expanded === 'panel3' ? (
                    <Icon name="ActionsControlsMinus" />
                  ) : (
                    <Icon name="ActionsControlsAdd" />
                  )
                }
              >
                <Typography variant="h6" className={classes.accordionHeading}>
                  {t('faq.accordion.titleThree')}
                </Typography>
              </ExpansionPanelSummaryCustom>
              <ExpansionPanelDetailsCustom className={classes.accordionContent}>
                <Typography>
                  {t('faq.accordion.descThree.title')}
                  <br />
                  <br />
                  {t('faq.accordion.descThree.ruleOne')}
                  <br />
                  {t('faq.accordion.descThree.ruleTwo')}
                  <br />
                  {t('faq.accordion.descThree.ruleThree')}
                  <br />
                  {t('faq.accordion.descThree.ruleFour')}
                  <br />
                  {t('faq.accordion.descThree.ruleFive')}
                </Typography>
              </ExpansionPanelDetailsCustom>
            </ExpansionPanelCustom>
            <ExpansionPanelCustom
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <ExpansionPanelSummaryCustom
                expandIcon={
                  expanded === 'panel4' ? (
                    <Icon name="ActionsControlsMinus" />
                  ) : (
                    <Icon name="ActionsControlsAdd" />
                  )
                }
              >
                <Typography variant="h6" className={classes.accordionHeading}>
                  {t('faq.accordion.titleFour')}
                </Typography>
              </ExpansionPanelSummaryCustom>
              <ExpansionPanelDetailsCustom className={classes.accordionContent}>
                <Typography> {t('faq.accordion.descFour')} </Typography>
              </ExpansionPanelDetailsCustom>
            </ExpansionPanelCustom>
            <ExpansionPanelCustom
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <ExpansionPanelSummaryCustom
                expandIcon={
                  expanded === 'panel5' ? (
                    <Icon name="ActionsControlsMinus" />
                  ) : (
                    <Icon name="ActionsControlsAdd" />
                  )
                }
              >
                <Typography variant="h6" className={classes.accordionHeading}>
                  {t('faq.accordion.titleFive')}
                </Typography>
              </ExpansionPanelSummaryCustom>
              <ExpansionPanelDetailsCustom className={classes.accordionContent}>
                <Typography>
                  {t('faq.accordion.descFive.title')}
                  <br />
                  <br />
                  {t('faq.accordion.descFive.ruleOne')}
                  <br />
                  {t('faq.accordion.descFive.ruleTwo')}
                  <br />
                  {t('faq.accordion.descFive.ruleThree')}
                  <br />
                  {t('faq.accordion.descFive.ruleFour')}
                </Typography>
              </ExpansionPanelDetailsCustom>
            </ExpansionPanelCustom>
            <ExpansionPanelCustom
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <ExpansionPanelSummaryCustom
                expandIcon={
                  expanded === 'panel6' ? (
                    <Icon name="ActionsControlsMinus" />
                  ) : (
                    <Icon name="ActionsControlsAdd" />
                  )
                }
              >
                <Typography variant="h6" className={classes.accordionHeading}>
                  {t('faq.accordion.titleSix')}
                </Typography>
              </ExpansionPanelSummaryCustom>
              <ExpansionPanelDetailsCustom className={classes.accordionContent}>
                <Typography> {t('faq.accordion.descSix')} </Typography>
              </ExpansionPanelDetailsCustom>
            </ExpansionPanelCustom>
          </div>
        </div>
      </Container>
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: '80px',
  },
  mainHeader: {
    marginTop: '60px',
    flexBasis: '10%',
    marginBottom: '20px',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
  },
  breadcrumps: {
    height: 56,
    marginBottom: 40,
    marginTop: 20,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 10,
    },
  },
  accordion: {
    width: '100%',
    marginTop: '60px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '12px',
    [theme.breakpoints.only('xs')]: {
      marginTop: 30,
    },
  },
  accordionHeading: {
    fontSize: theme.typography.fontSize * 1.3,
    color: '#202020',
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.fontSize * 1.1,
    },
  },
  accordionContent: {
    [theme.breakpoints.only('xs')]: {
      margin: '0 10px 14px',
      padding: 18,
    },
  },
}));
