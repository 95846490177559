import React, { Fragment } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Typography,
  Container,
  Breadcrumbs,
  Grid,
  Link,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Icon from '@bappweb/icon';
import { colors } from '../../theme/colors';
import Image from '@bappweb/image';
import { useGetList } from 'react-admin';
import { useTranslate } from 'ra-core';
import { useUserContext } from 'layer7-apihub';

const StyledMenu = withStyles({
  paper: {
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.16);',
    marginTop: '5px',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      background: '#f5f4f6',
    },
    '& span': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))(props => <MenuItem {...props} />);

export const Dashboard = () => {
  const { API_PORTAL_URL } = global.APIHUB_CONFIG;
  const t = useTranslate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userContext] = useUserContext();
  const [canCreateApp, setCanCreateApp] = React.useState(false);

  React.useEffect(() => {
    if (userContext && !userContext.userDetails.developer) {
      setCanCreateApp(true);
    }
  }, [userContext]);

  const { data, loaded } = useGetList(
    'applications',
    undefined,
    { field: 'name', order: 'ASC' },
    undefined
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLocalizedStatus = statusText => {
    if (statusText === 'ENABLED') {
      return t('applicationStatus.enabled');
    }
    if (statusText === 'DISABLED') {
      return t('applicationStatus.disabled');
    }
    if (statusText === 'UNPUBLISHED') {
      return t('applicationStatus.unpublished');
    }
    if (statusText === 'APPLICATION_PENDING_APPROVAL') {
      return t('applicationStatus.applicationPendingApproval');
    }
    if (statusText === 'EDIT_APPLICATION_PENDING_APPROVAL') {
      return t('applicationStatus.editApplicationPendingApproval');
    }
    if (statusText === 'DEPRECATED') {
      return t('applicationStatus.deprecated');
    }
    if (statusText === 'REJECTED') {
      return t('applicationStatus.rejected');
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem
          component={'a'}
          href={API_PORTAL_URL + 'admin/app/analytics-dashboard'}
          target="_blank"
          rel="noreferrer"
          onClick={handleClose}
        >
          <ListItemText primary={t('dashboard.applicationTraffic')} />
        </StyledMenuItem>
        <StyledMenuItem
          component={'a'}
          href={API_PORTAL_URL + 'admin/app/analytics-dashboard/usage'}
          target="_blank"
          rel="noreferrer"
          onClick={handleClose}
        >
          <ListItemText primary={t('dashboard.quotaConsumption')} />
        </StyledMenuItem>
        <StyledMenuItem
          component={'a'}
          href={API_PORTAL_URL + 'admin/app/analytics-dashboard/custom-report'}
          target="_blank"
          rel="noreferrer"
          onClick={handleClose}
        >
          <ListItemText primary={t('dashboard.customReports')} />
        </StyledMenuItem>
      </StyledMenu>
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<Icon name="NavigationRight" size={22} />}
          className={classes.breadcrumps}
        >
          <Link color="inherit" to="/" component={RouterLink}>
            {t('common.home')}
          </Link>
          <Typography color="textPrimary">{t('dashboard.breakcrumbText')}</Typography>
        </Breadcrumbs>
        <div className={classes.header}>
          <Typography variant="h2" className={classes.mainHeader}>
            {t('dashboard.header')}
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.mainHeaderSubtitle} color="textSecondary">
              {t('dashboard.subHeader')}
            </Typography>
          </Grid>
        </Grid>
        {!loaded && (
          <Grid container justify="center" className={classes.loadingGrid}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {loaded && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            className={classes.appListActionButtons}
            spacing={1}
          >
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleClick}>
                {t('dashboard.monitorYourApplication')}
                {anchorEl === null && <Icon name="NavigationDown" size={24} />}
                {anchorEl !== null && <Icon name="NavigationUp" size={24} />}
              </Button>
            </Grid>
            <Grid item>
              {canCreateApp && (
                <Button
                  variant="outlined"
                  color="primary"
                  to={'/applications/create'}
                  component={RouterLink}
                >
                  {!data
                    ? t('dashboard.addYourFirstApplication')
                    : t('dashboard.addNewApplication')}
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        {loaded && data && Object.keys(data).length === 0 && (
          <div
            className={clsx({
              [classes.appList]: true,
              [classes.appListEmpty]: true,
            })}
          >
            <Typography variant="h6" color="textSecondary">
              {canCreateApp
                ? t('dashboard.noProjectText')
                : t('dashboard.noProjectPermissionError')}
            </Typography>
            {canCreateApp && (
              <Button
                variant="text"
                color="primary"
                className={classes.createAppButton1}
                startIcon={<Icon name="ActionsControlsAddStyle" />}
                to={'/applications/create'}
                component={RouterLink}
              >
                {t('dashboard.createYourFirstApplicationText')}
              </Button>
            )}
          </div>
        )}

        {loaded && data && Object.keys(data).length > 0 && (
          <div className={classes.appList}>
            <Typography className={classes.myApplicationsHeader} variant="h6" color="textSecondary">
              {t('dashboard.myApplications')} ({Object.keys(data).length})
            </Typography>
            <div className={classes.appListAppGrid}>
              <div className={classes.appListAppGridRow}>
                <div className={classes.appListAppGridColumn}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.appListAppGridHeaderCellText}
                  >
                    {t('dashboard.gridApplicationNameColumnHeader')}
                  </Typography>
                </div>
                <div className={classes.appListAppGridColumn}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.appListAppGridHeaderCellText}
                  >
                    {t('dashboard.gridStatusColumnHeader')}
                  </Typography>
                </div>
                <div
                  className={clsx({
                    [classes.appListAppGridColumnButtons]: true,
                    [classes.appListAppGridColumn]: true,
                  })}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.appListAppGridHeaderCellText}
                  >
                    {t('dashboard.gridActionsHeader')}
                  </Typography>
                </div>
              </div>
              {Object.keys(data).map((key, index) => {
                return (
                  <div className={classes.appListAppGridRow} key={'api_' + key}>
                    <div className={classes.appListAppGridColumn}>
                      <Typography className={classes.appListAppGridRowAppNameText}>
                        {data[key].name}
                      </Typography>
                    </div>
                    <div className={classes.appListAppGridColumn}>
                      <Typography type="secondary">
                        {getLocalizedStatus(data[key].status)}
                      </Typography>
                    </div>
                    <div
                      className={clsx({
                        [classes.appListAppGridColumnButtons]: true,
                        [classes.appListAppGridColumn]: true,
                      })}
                    >
                      <Button
                        variant="text"
                        color="default"
                        to={'/applications/' + data[key].id + '/show'}
                        component={RouterLink}
                        startIcon={<Icon name="ActionsControlsNote" size={20} />}
                      >
                        {t('dashboard.edit')}
                      </Button>
                      {/* <Button
                        variant="text"
                        color="default"
                        startIcon={<Icon name="ActionsControlsDelete" size={20} />}
                      >
                        Delete
                      </Button> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Container>
      <div className={classes.whatsNextSection}>
        <Container maxWidth="lg">
          <Fragment>
            <Typography variant="h4" className={classes.whatsNextHeader}>
              {t('dashboard.unsureTextHeader')}
            </Typography>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.whatsNextHeaderSubtitle}
                >
                  {t('dashboard.unsureTextSubHeader')}
                </Typography>
              </Grid>
            </Grid>
          </Fragment>

          <div className={classes.whatsNextInfoBoxes}>
            <div className={classes.whatNextBox}>
              <div className={classes.whatNextBoxInner}>
                <Typography className={classes.hatsNextBoxOrderNumber}>1</Typography>
                <Image
                  className={classes.whatsNextBoxImage}
                  path={'/img/svg/Welcome.svg'}
                  fallbackText="Welcome"
                />
              </div>
              <Typography varian="h5" className={classes.whatsNextBoxTitle}>
                {t('dashboard.whatsNextItem1Title')}
              </Typography>
              <Typography varian="p" className={classes.whatsNextBoxDesc}>
                {t('dashboard.whatsNextItem1')}
              </Typography>
            </div>

            <div className={classes.whatNextBox}>
              <div className={classes.whatNextBoxInner}>
                <Typography className={classes.hatsNextBoxOrderNumber}>2</Typography>
                <Image
                  className={classes.whatsNextBoxImage}
                  path={'/img/svg/Create Project.svg'}
                  fallbackText="Create Project"
                />
              </div>
              <Typography varian="h5" className={classes.whatsNextBoxTitle}>
                {t('dashboard.whatsNextItem2Title')}
              </Typography>
              <Typography varian="p" className={classes.whatsNextBoxDesc}>
                {t('dashboard.whatsNextItem2')}
              </Typography>
            </div>
            <div className={classes.whatNextBox}>
              <div className={classes.whatNextBoxInner}>
                <Typography className={classes.hatsNextBoxOrderNumber}>3</Typography>
                <Image
                  className={classes.whatsNextBoxImage}
                  path={'/img/svg/Test APIs.svg'}
                  fallbackText="Test APIs"
                />
              </div>
              <Typography varian="h5" className={classes.whatsNextBoxTitle}>
                {t('dashboard.whatsNextItem3Title')}
              </Typography>
              <Typography varian="p" className={classes.whatsNextBoxDesc}>
                {t('dashboard.whatsNextItem3')}
              </Typography>
            </div>
            <div className={classes.whatNextBox}>
              <div className={classes.whatNextBoxInner}>
                <Typography className={classes.hatsNextBoxOrderNumber}>4</Typography>
                <Image
                  className={classes.whatsNextBoxImage}
                  path={'/img/svg/Release Project.svg'}
                  fallbackText="Release Project"
                />
              </div>
              <Typography varian="h5" className={classes.whatsNextBoxTitle}>
                {t('dashboard.whatsNextItem4Title')}
              </Typography>
              <Typography varian="p" className={classes.whatsNextBoxDesc}>
                {t('dashboard.whatsNextItem4')}
              </Typography>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '100px',
  },
  breadcrumps: {
    height: 56,
    marginBottom: 20,
    marginTop: 20,
  },
  header: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'start',
  },
  mainHeader: {
    flexBasis: '10%',
    marginBottom: '20px',
  },
  mainHeaderSubtitle: {
    fontSize: theme.typography.pxToRem(16),
  },
  appListActionButtons: {
    marginTop: '40px',
    [theme.breakpoints.only('xs')]: {
      '& div': {
        width: '100%',
        '& button, & a': {
          width: '100%',
        },
      },
    },
  },
  appList: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
    minHeight: '184px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '12px',
    marginTop: '20px',
    padding: '20px',
    boxSizing: 'border-box',
  },
  appListEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  appListAppGrid: {},
  appListAppGridHeader: {},
  appListAppGridHeaderCellText: {
    fontSize: theme.typography.pxToRem(14),
  },
  appListAppGridRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    height: '72px',
    borderBottomColor: colors.secondary_gray_tertiary,
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:first-child': {
      borderBottom: 'none',
    },
  },
  appListAppGridRowAppNameText: {
    fontWeight: '500',
  },
  appListAppGridColumn: {
    display: 'flex',
    width: '33%',
  },
  appListAppGridColumnButtons: {
    justifyContent: 'space-between',
    width: '15%',
  },
  whatsNextSection: {
    backgroundColor: colors.primary_akbank_white,
    minHeight: '515px',
    marginTop: '112px',
  },
  whatsNextHeader: {
    color: colors.primary_akbank_red,
    marginTop: '40px',
    fontSize: theme.typography.pxToRem(24),
  },
  whatsNextHeaderSubtitle: {
    marginTop: '8px',
    marginBottom: 36,
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  createAppButton1: {
    fontSize: '1rem',
  },
  whatsNextInfoBoxes: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  whatNextBox: {
    width: '310px',
    margin: '4px 0 0 16px',
    flex: 1,
    flexDirection: 'column',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  whatNextBoxInner: {
    //width: '290px',
    height: '204px',
    borderRadius: '12px',
    backgroundColor: colors.secondary_gray_tertiary,
    position: 'relative',
  },
  hatsNextBoxOrderNumber: {
    color: colors.primary_akbank_red,
    fontSize: theme.typography.pxToRem(24),
    left: 20,
    top: 16,
    position: 'relative',
    fontWeight: '500',
  },
  whatsNextBoxTitle: {
    fontSize: theme.typography.pxToRem(18),
    color: colors.primary_akbank_red,
    marginTop: '16px',
    textAlign: 'center',
    fontWeight: 500,
  },
  whatsNextBoxDesc: {
    fontSize: theme.typography.pxToRem(14),
    color: colors.secondary_gray_primary,
    marginTop: '8px',
    textAlign: 'center',
  },
  whatsNextBoxImage: {
    display: 'block',
    margin: '0 auto',
    width: '156px',
    height: '156px',
  },
  myApplicationsHeader: {
    color: colors.secondary_akbank_black,
    marginBottom: '10px',
  },
  loadingGrid: {
    marginTop: 50,
  },
  firstApplicationCard: {
    marginTop: '24px',
    padding: '56px 0px',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
    color: '#a6a1ad',
  },
  buttonlink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 300,
    marginTop: '16px',
  },
}));
