import React from 'react';
import {
  makeStyles,
  Typography,
  Button,
  Container,
  Grid,
  Hidden,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import Slider from '../../components/Slider';
//import UseCaseCard from '../../components/UseCaseCard';
import { Link as RouterLink } from 'react-router-dom';
import ApiCard from '../../components/ApiCard';
import Image from '@bappweb/image';
import { useTranslate } from 'ra-core';
import apiListEn from '../../files/home_api_list-en.json';
import useCaseList from '../../files/use_cases_list.json';
import { useAuthState, useLocale } from 'ra-core';
import DataLayer from '../../core/Ga_dataLayer';

export const HomePage = () => {
  const { ENVIRONMENT } = global.APIHUB_CONFIG;
  const t = useTranslate();
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();
  const locale = useLocale();

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container className={classes.developmentForContainer}>
            <Grid xs={12} sm={6} item>
              <Typography className={classes.motto} variant="h2" color="primary">
                {t('home.heroHeader')}
              </Typography>
              <Typography variant="h6" color="primary">
                {t('home.heroSubHeader')}
              </Typography>
              <Hidden xsDown>
                <div className={classes.buttonContainer}>
                  {!authenticated && !loading && (
                    <Button
                      style={{ marginRight: 10 }}
                      variant="contained"
                      color="primary"
                      to="/login"
                      component={RouterLink}
                      disableElevation
                      onClick={() =>
                        DataLayer(
                          'button_click',
                          `${t('home.getStartedButton')}`,
                          'header',
                          window.location.hash,
                          '/login'
                        )
                      }
                    >
                      {t('home.getStartedButton')}
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    color="secondary"
                    to="/how-it-works"
                    component={RouterLink}
                    onClick={() =>
                      DataLayer(
                        'button_click',
                        `${t('home.howItWorksButton')}`,
                        'header',
                        window.location.hash,
                        '/how-it-works'
                      )
                    }
                  >
                    {t('home.howItWorksButton')}
                  </Button>
                </div>
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.heroImageContainer}>
                <Image
                  className={classes.heroImage}
                  path={'/img/svg/hero-red.svg'}
                  fallbackText="Hero image"
                />
              </div>
              <Hidden smUp>
                <div className={classes.buttonContainer}>
                  {!authenticated && !loading && (
                    <Button
                      variant="contained"
                      color="primary"
                      to="/login"
                      component={RouterLink}
                      fullWidth
                      onClick={() =>
                        DataLayer(
                          'button_click',
                          `${t('home.getStartedButton')} mobil`,
                          'header',
                          window.location.hash,
                          '/login'
                        )
                      }
                    >
                      {t('home.getStartedButton')}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="secondary"
                    to="/how-it-works"
                    component={RouterLink}
                    fullWidth
                    onClick={() =>
                      DataLayer(
                        'button_click',
                        `${t('home.howItWorksButton')} button mobil`,
                        'header',
                        window.location.hash,
                        '/how-it-works'
                      )
                    }
                  >
                    {t('home.howItWorksButton')}
                  </Button>
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container className={classes.whatCanYouDoContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center">
                {t('home.bigApiCardHeader')}
              </Typography>
            </Grid>
            <Grid item container spacing={3}>
              {apiListEn[ENVIRONMENT].WhatCanYouDoSection.map((item, index) => (
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.whatCanYouDoContent}
                  key={'wcyd' + index}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h4" color="primary">
                      {item.title}
                      {t('home.whatCanYouDoItems.item' + item.number + 'Text')}
                    </Typography>
                    <Typography
                      className={classes.whatCanYouDoDescription}
                      variant="h6"
                      color="textSecondary"
                    >
                      {t('home.whatCanYouDoItems.item' + item.number + 'Description')}
                    </Typography>
                    <Button
                      variant="text"
                      color="primary"
                      component={RouterLink}
                      to={'/apis/' + item.uuid + '/show'}
                      onClick={() =>
                        DataLayer(
                          'button_click',
                          `${t('home.whatCanYouDoItems.item' + item.number + 'Text')}`,
                          'body',
                          window.location.hash,
                          !authenticated ? 'login' : `${'apis/' + item.uuid + '/show'}`
                        )
                      }
                    >
                      {t('home.learnButtonMutton')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Image path={`/img/svg/${item.svg}`} className={classes.whatCanYouDoImage} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" align="center" className={classes.ourApisHeader}>
                {t('home.ourApisHeader')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.ourApisHeaderSubHeder}
              >
                {t('home.ourApisSubHeader')}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ourApiSeeMoreContainer}>
              <Button
                variant="text"
                color="primary"
                to="/api-list"
                component={RouterLink}
                onClick={() =>
                  DataLayer(
                    'button_click',
                    'See More Button',
                    'body',
                    window.location.hash,
                    !authenticated ? 'login' : 'api-list'
                  )
                }
              >
                {t('home.seeMoreButton')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Slider fullWidthButtons={false} slidesToShow={3}>
                {locale &&
                  locale === 'en' &&
                  apiListEn[ENVIRONMENT].OurApisSectionEN.map((card, i) => (
                    <ApiCard
                      key={'api_card' + card.id}
                      {...card}
                      even={i % 2 === 0}
                      buttonName={t('home.sliderButton')}
                    />
                  ))}

                {locale &&
                  locale !== 'en' &&
                  apiListEn[ENVIRONMENT].OurApisSectionTR.map((card, i) => (
                    <ApiCard
                      key={'api_card' + card.id}
                      {...card}
                      even={i % 2 === 0}
                      buttonName={t('home.sliderButton')}
                    />
                  ))}
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" align="center" className={classes.ourApisHeader}>
                {t('home.useCasesHeader')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.ourApisHeaderSubHeder}
              >
                {t('home.ourApisSubHeader')}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.ourApiSeeMoreContainer}>
              <Button
                variant="text"
                color="primary"
                to="/use-cases"
                component={RouterLink}
                onClick={() =>
                  DataLayer(
                    'button_click',
                    'See all button',
                    'body',
                    window.location.hash,
                    'use-cases'
                  )
                }
              >
                {t('home.seeMoreButton')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Slider fullWidthButtons={false} slidesToShow={4}>
                {locale &&
                  locale === 'en' &&
                  useCaseList[ENVIRONMENT].UseCasesEN.map((card, i) => (
                    <Card className={classes.cardContainer} key={'usecase_card' + i}>
                      <CardMedia component="img" height="180" image={card.url} />
                      <CardContent className={classes.cardContent}>
                        <Typography className={classes.cardTitle} variant="h6">
                          {card.name}
                        </Typography>
                        <Typography className={classes.cardDescription} color="textSecondary">
                          {card.description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          className={classes.cardButton}
                          variant="outlined"
                          color="primary"
                          to={'/use-case-detail/' + card.uuid}
                          component={RouterLink}
                          onClick={() =>
                            DataLayer(
                              'button_click',
                              `${card.name} usecase button`,
                              'body',
                              window.location.hash,
                              `${'use-case-detail/' + card.uuid}`
                            )
                          }
                        >
                          {t('home.useCaseDetailButton')}
                        </Button>
                      </CardActions>
                    </Card>
                  ))}

                {locale &&
                  locale !== 'en' &&
                  useCaseList[ENVIRONMENT].UseCasesTR.map((card, i) => (
                    <Card className={classes.cardContainer} key={'usecase_card' + i}>
                      <CardMedia component="img" height="180" image={card.url} />
                      <CardContent className={classes.cardContent}>
                        <Typography className={classes.cardTitle} variant="h6">
                          {card.name}
                        </Typography>
                        <Typography className={classes.cardDescription} color="textSecondary">
                          {card.description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          to={'/use-case-detail/' + card.uuid}
                          component={RouterLink}
                          className={classes.cardButton}
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            DataLayer(
                              'button_click',
                              `${card.name} usecase button`,
                              'body',
                              window.location.hash,
                              `${'use-case-detail/' + card.uuid}`
                            )
                          }
                        >
                          {t('home.useCaseDetailButton')}
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    justifyContent: 'center',
    '&:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
  developmentForContainer: {
    display: 'flex',
    marginTop: 88,
    marginBottom: 96,
    justifyContent: 'space-between',
  },
  whatCanYouDoContainer: {
    marginTop: 48,
    marginBottom: 48,
  },
  whatCanYouDoContent: {
    marginTop: 88,
    marginBottom: 96,
    '&:nth-child(odd)': {
      flexDirection: 'row-reverse',
    },
  },
  heroImageContainer: {
    textAlign: 'right',
  },
  heroImage: {
    maxWidth: '350px',
    [theme.breakpoints.only('xs')]: {
      marginTop: '48px',
      width: '100%',
    },
  },
  whatCanYouDoImage: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '100%',
    },
  },
  whatCanYouDoDescription: {
    padding: '16px 0',
  },
  motto: {
    marginBottom: 8,
  },
  buttonContainer: {
    '& button': {
      marginRight: 16,
    },
    marginTop: 48,
    [theme.breakpoints.only('xs')]: {
      '& button': {
        marginRight: 0,
        marginBottom: '18px',
      },
      '& > button, & > a': {
        height: '56px',
        marginBottom: '18px',
      },
    },
  },
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  markdown: {
    overflowWrap: 'anywhere',
    color: theme.palette.text.primary,
    '& > p': {
      maxWidth: '40%',
      marginTop: theme.spacing(4),
      fontSize: theme.typography.fontSize * 1.5,
    },
  },
  logo: {
    position: 'fixed',
    zIndex: -1,
    bottom: '15%',
    right: '10%',
    maxWidth: '30vw',
  },
  slick: {
    display: 'flex !important',
    flexDirection: 'row',
    width: '100%',
  },
  test: {
    position: 'relative',
  },
  ourApisHeader: {
    fontSize: theme.typography.pxToRem(48),
    textAlign: 'left',
    fontWeight: 500,
    marginTop: '60px',
    marginBottom: '20px',
  },
  ourApisHeaderSubHeder: {
    marginBottom: '20px',
    fontSize: theme.typography.pxToRem(18),
  },
  ourApiSeeMoreContainer: {
    textAlign: 'right',
  },
  cardContainer: {
    maxWidth: '95%',
    minHeight: '500px',
    margin: '0 10px',
    backgroundColor: 'white',
  },
  cardTitle: {
    marginTop: '30px',
    marginBottom: '12px',
    minHeight: '50px',
  },
  cardButton: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
  cardDescription: {
    minHeight: '90px',
  },
  cardContent: {
    minHeight: '240px',
    [theme.breakpoints.only('md')]: {
      minHeight: '280px',
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: '180px',
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: '180px',
    },
  },
}));
