const colors = {
  primary_akbank_red: '#dc0005',
  primary_akbank_white: '#fff',
  secondary_akbank_black: '#202020',
  secondary_amelyst: '#8079af',
  secondary_gray_primary: '#5d5864',
  secondary_gray_secondary: '#a6a1ad',
  secondary_gray_tertiary: '#e6e5e9',
  secondary_kaftan: '#342942',
  secondary_off_white: '#f5f4f6',
  supporting_american_pink: '#fa9696',
  supporting_black_olive: '#2c2b32',
  supporting_bright_red: '#af0005',
  supporting_dark_chocolate_red: '#550005',
  supporting_davy_s_grey: '#59585f',
  supporting_flamingo_pink: '#f04b4b',
  supporting_jumbo_grey: '#808086',
  supporting_light_pink: '#fac8c8',
  supporting_mischka_grey: '#dad9e0',
  supporting_pastel_pink: '#f06464',
  supporting_root_beer_red: '#280005',
  supporting_sangria_red: '#920005',
  supporting_silver_grey: '#abaab3',
  products_axess: '#fac40c',
  products_free: '#888',
  products_wings: '#00303e',
  validation_confirmation: '#1dad65',
  validation_error: '#ff6e64',
  validation_warning: '#ffa564',
};

export { colors };
