import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useTranslate } from 'ra-core';
import { required, email as checkEmail } from '@bappweb/utils';
import Form from '@bappweb/form';
import Block from '@bappweb/block';
import TextField from '../components/TextField';
import { useSignup, useLayer7Notify } from 'layer7-apihub';
import { Link as RouterLink } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const SignUpForm = () => {
  const classes = useStyles();
  const t = useTranslate();
  const notify = useLayer7Notify();
  const [signup] = useSignup();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { ENVIRONMENT } = window.APIHUB_CONFIG;

  useEffect(() => {
    loadCaptchaEnginge(8, '#f5f4f6', 'black', 'upper');
  }, []);

  const handleSubmit = (e, data) => {
    if (
      validateCaptcha(data.captcha) === true ||
      (ENVIRONMENT === 'Uat' && data.captcha === '123456') // Added for testing on UAT environment
    ) {
      loadCaptchaEnginge(8, '#f5f4f6', 'black', 'upper');
    } else {
      notify(t('captcha.error'), 'warning');
      return;
    }

    setIsLoading(true);

    delete data.EmailConfirmation;
    delete data.captcha;

    signup(data, {
      onSuccess: () => {
        setIsLoading(false);
        notify(t('register.registerSuccess'), 'success');
        setShowConfirmation(true);
      },
      onFailure: error => {
        setIsLoading(false);
        console.log(error);
        notify(error || t('register.registerError'), 'error');
      },
    });
  };

  const handleError = (e, errors) => {
    console.info(JSON.stringify(errors, void 0, 2));
  };

  return (
    <Block className={classes.bappAppPageSignUpMain}>
      <Block className={classes.bappPageSignUpMainWrapper}>
        <Typography variant="h2" className={classes.title}>
          {t('register.title')}
        </Typography>
        {showConfirmation ? (
          <React.Fragment>
            <Typography component="p" variant="h6" gutterBottom>
              {t('register.confirmation_title')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('register.confirmation')}
            </Typography>
            <Typography variant="body1">
              <Link component={RouterLink} to="/">
                {t('register.return_to_homepage')}
              </Link>
            </Typography>
          </React.Fragment>
        ) : (
          <Form
            onSubmit={handleSubmit}
            onError={handleError}
            className={classes.signUpForm}
            grid={false}
          >
            <TextField
              label={t('register.form.email')}
              placeholder={t('register.form.email_placeholder')}
              type="text"
              name="Email"
              validations={[
                required(t('validation.required')),
                checkEmail(t('validation.checkEmail')),
              ]}
            />
            <TextField
              label={t('register.form.confirm_email')}
              placeholder={t('register.form.confirm_email_placeholder')}
              type="text"
              name="EmailConfirmation"
              validations={[
                required(t('validation.required')),
                checkEmail(t('validation.checkEmail')),
              ]}
            />
            <TextField
              label={t('register.form.organization')}
              placeholder={t('register.form.organization_placeholder')}
              type="text"
              name="OrganizationName"
              validations={[required(t('validation.required'))]}
            />
            <TextField
              label={t('register.form.organization_description')}
              placeholder={t('register.form.organization_description_placeholder')}
              multiline
              type="text"
              name="OrganizationDescription"
              validations={[required(t('validation.required'))]}
            />

            <div className={classes.captchaStyle}>
              <LoadCanvasTemplate reloadText={t('captcha.reload')} reloadColor="#dc0005" />
            </div>

            <TextField
              label={t('captcha.text')}
              placeholder=""
              type="text"
              name="captcha"
              validations={[required(t('validation.required'))]}
            />

            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              disabled={isLoading}
              className={classes.submit}
              size="large"
            >
              {t('register.form.signup')}
            </Button>
          </Form>
        )}
      </Block>
    </Block>
  );
};

const useStyles = makeStyles(theme => ({
  bappAppPageSignUpMain: {
    //width: 400,
    marginTop: 100,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    marginBottom: '32px',
    fontSize: '24px',
  },
  bappPageSignUpMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  signUpForm: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  submit: {
    marginTop: 24,
  },
  captchaStyle: {
    border: '1px solid rgb(204, 204, 204)',
    textAlign: 'center',
    margin: '10px auto 0',
    padding: '10px',
    width: '90%',
    boxSizing: 'border-box',
  },
}));

export default SignUpForm;
