import React from 'react';
import { ApplicationEdit as DefaultApplicationEdit } from 'layer7-apihub';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

export const ApplicationEdit = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid className={classes.form}>
          <DefaultApplicationEdit {...props} />
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '100px',
    },
    form: {
      '&.MuiGrid-root h2': {
        color: '#000',
        fontSize: '2rem',
      },
      '& .MuiGrid-root': {
        backgroundColor: '#fff',
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  }),
  {
    name: 'ApplicationCreate',
  }
);
