import React from 'react';
import { Show, SimpleShowLayout, TextField, EditButton } from 'react-admin';
import { makeStyles, Container } from '@material-ui/core';
import { ViewTitle } from './ViewTitle';
import { UserContextTitle } from './UserContextTitle';
import { UserContextSubtitle } from './UserContextSubtitle';

export const UserContextShow = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <ViewTitle />
        <Show
          {...props}
          title={<UserContextTitle actions={<EditButton />} {...props} />}
          actions={null}
        >
          <SimpleShowLayout className={classes.showLayout}>
            <TextField className={classes.field} source="userDetails.username" />
            <TextField className={classes.field} source="userDetails.lastName" />
            <TextField className={classes.field} source="userDetails.firstName" />
            <TextField className={classes.field} source="userDetails.email" />
            <UserContextSubtitle />
            <TextField className={classes.field} source="userDetails.password" type="password" />
          </SimpleShowLayout>
        </Show>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '100px',
      padding: 0,
    },
    title: {
      color: '#000',
      fontSize: '2rem',
      backgroundColor: theme.palette.background.paper,
      marginTop: 24,
      marginBottom: 8,
    },
    showLayout: {
      maxWidth: '100%',
      flexBasis: '100%',
      backgroundColor: '#fff',
    },
    field: {
      width: 456,
    },
  }),
  {
    name: 'Layer7UserContextShow2',
  }
);
