import React, { useCallback, useState } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  Breadcrumbs,
  Link,
  Hidden,
  Grid,
} from '@material-ui/core';
import Icon from '@bappweb/icon';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';
import Lottie from 'react-lottie';
import welcomeAnim from '../../ui/animations/how-it-works/1-Welcome.json';
import createAnim from '../../ui/animations/how-it-works/2-Create.json';
import testAnim from '../../ui/animations/how-it-works/3-Test.json';
import releaseAnim from '../../ui/animations/how-it-works/4-Release.json';

const steps = [
  {
    number: 1,
    lottie: welcomeAnim,
  },
  {
    number: 2,
    lottie: createAnim,
  },
  {
    number: 3,
    lottie: testAnim,
  },
  {
    number: 4,
    lottie: releaseAnim,
  },
];

export const HowItWorks = () => {
  const t = useTranslate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [lottieOptions, setLottieOptions] = useState({
    loop: false,
    autoplay: true,
    animationData: welcomeAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const getAnimation = stepNumber => {
    if (stepNumber === 1) {
      return welcomeAnim;
    } else if (stepNumber === 2) {
      return createAnim;
    } else if (stepNumber === 3) {
      return testAnim;
    } else if (stepNumber === 4) {
      return releaseAnim;
    }
    return '';
  };

  React.useEffect(() => {
    setLottieOptions({ ...lottieOptions, animationData: steps[activeStep].lottie });
    const timeOutId = setTimeout(() => {
      setActiveStep(prevStep => (activeStep === steps.length - 1 ? 0 : prevStep + 1));
    }, 5000);
    return () => clearTimeout(timeOutId);
  }, [activeStep]);

  const handleClick = useCallback(
    index => () => {
      setActiveStep(index);
    },
    []
  );

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.section}>
          <Breadcrumbs
            separator={<Icon name="NavigationRight" size={22} />}
            className={classes.breadcrumps}
          >
            <Link color="inherit" to="/" component={RouterLink}>
              {t('common.home')}
            </Link>
            <Typography color="textPrimary"> {t('howItWorks.breadcrumbText')}</Typography>
          </Breadcrumbs>
          <Typography variant="h2" align="center" className={classes.mainHeader}>
            {t('howItWorks.header')}
          </Typography>
          <Hidden smDown>
            <Grid container alignItems="center">
              <Grid md={8}>
                <div className={classes.bigImageContainer}>
                  <div className={classes.bigImage}>
                    <Lottie options={lottieOptions} />
                  </div>
                </div>
              </Grid>
              <Grid md={4}>
                <div className={classes.stepper}>
                  {steps.map((step, index) => {
                    return (
                      <div className={classes.stepItem} key={'how-it-works' + index}>
                        <div
                          className={clsx({
                            [classes.stepItemCircleWrapper]: true,
                            passive: activeStep !== index,
                            active: activeStep === index,
                          })}
                        >
                          <div
                            onClick={handleClick(index)}
                            className={clsx({
                              [classes.stepItemStepCircleActive]: activeStep === index,
                              [classes.stepItemStepCirclePassive]: activeStep !== index,
                            })}
                          ></div>
                        </div>
                        <div className={classes.stepInfoWrapper}>
                          <p
                            className={clsx({
                              [classes.stepItemStepNumber]: true,
                              active: activeStep === index,
                            })}
                          >
                            {index + 1}. {t('howItWorks.step')}
                          </p>
                          <p
                            className={clsx({
                              [classes.stepItemStepName]: true,
                              passive: activeStep !== index,
                            })}
                          >
                            {t('howItWorks.steps.step' + step.number + 'Text')}
                          </p>
                          <p
                            className={clsx({
                              [classes.stepItemDescription]: true,
                              passive: activeStep !== index,
                            })}
                          >
                            {activeStep === index &&
                              t('howItWorks.steps.step' + step.number + 'Description')}
                          </p>
                          {index < 3 && <span className={classes.stepConnectorVertical}></span>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Container maxWidth="lg">
              {steps.map((step, index) => {
                return (
                  <div className={classes.stepItemMobile} key={'how-it-works' + index}>
                    <div className={classes.stepImageContainer}>
                      <div className={classes.stepImage}>
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: getAnimation(step.number),
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.stepInfoWrapperMobile}>
                      <p className={classes.stepItemStepNumberMobile}>
                        {index + 1}. {t('howItWorks.step')}
                      </p>
                      <p className={classes.stepItemStepNameMobile}>
                        {t('howItWorks.steps.step' + step.number + 'Text')}
                      </p>
                      <p className={classes.stepItemDescriptionMobile}>
                        {t('howItWorks.steps.step' + step.number + 'Description')}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Container>
          </Hidden>
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumps: {
    height: 56,
    marginTop: 20,
  },
  section: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  mainHeader: {
    marginTop: '0',
    flexBasis: '10%',
    marginBottom: '60px',
    [theme.breakpoints.only('lg')]: {
      fontSize: theme.typography.pxToRem(48),
      marginTop: '0',
      marginBottom: '40px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.typography.pxToRem(24),
      marginTop: '0',
      marginBottom: '40px',
    },
  },
  bigImageContainer: {
    display: 'flex',
    flexBasis: '60%',
    justifyContent: 'center',
  },
  bigImage: {
    backgroundColor: '#e6e5e9',
    borderRadius: '80px',
    height: '600px',
    width: '600px',
    [theme.breakpoints.only('md')]: {
      height: '500px',
      width: '500px',
      borderRadius: '40px',
    },
    [theme.breakpoints.only('xs')]: {
      height: '100%',
      width: '100%',
    },
  },
  stepper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stepItem: {
    display: 'flex',
    margin: '20px 20px 0',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
  },
  stepItemStepNumber: {
    color: '#a6a1ad',
    marginBottom: '8px',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    '&.active': {
      color: '#202020',
    },
  },
  stepItemCircleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 50,
    height: '50px',
    position: 'absolute',
    left: '-54px',
    alignItems: 'flex-end',
    '&.passive': {
      top: '-4px',
    },
  },
  stepItemStepCircleActive: {
    backgroundColor: '#dc0005',
    borderRadius: '50%',
    width: 32,
    height: 32,
    cursor: 'pointer',
    transition: 'all .4s',
  },
  stepItemStepCirclePassive: {
    backgroundColor: '#a6a1ad',
    borderRadius: '50%',
    width: 20,
    height: 20,
    cursor: 'pointer',
    transition: 'all .4s',
  },
  stepItemStepName: {
    color: '#dc0005',
    marginBottom: '8px',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(24),
    '&.passive': {
      color: '#a6a1ad',
    },
  },
  stepItemDescription: {
    color: '#202020',
    marginBottom: '8px',
    fontSize: theme.typography.pxToRem(16),
    '&.passive': {
      color: '#a6a1ad',
    },
  },
  stepConnector: {
    top: '30px',
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 40px)',
    position: 'absolute',
    flex: '1 1 auto',
  },
  stepConnectorLine: {
    borderColor: '#a6a1ad',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    display: 'block',
  },
  stepConnectorVertical: {
    width: '1px',
    height: '70%',
    background: '#a6a1ad',
    display: 'block',
    position: 'absolute',
    left: -30,
    top: 66,
  },
  stepInfoWrapper: {
    marginTop: '10px',
    textAlign: 'left',
  },
  stepItemMobile: {
    display: 'flex',
    margin: '20px 20px 72px 20px',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'relative',
  },
  stepItemStepNumberMobile: {
    marginBottom: '8px',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: '#202020',
  },
  stepItemStepNameMobile: {
    color: '#dc0005',
    marginBottom: '8px',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(24),
  },
  stepItemDescriptionMobile: {
    color: '#202020',
    marginBottom: '8px',
    fontSize: theme.typography.pxToRem(16),
  },
  stepInfoWrapperMobile: {
    marginTop: '10px',
  },
  stepImageContainer: {
    display: 'flex',
    flexBasis: '60%',
    justifyContent: 'center',
  },
  stepImage: {
    backgroundColor: '#e6e5e9',
    borderRadius: '80px',
    [theme.breakpoints.only('xs')]: {
      height: '100%',
      width: '100%',
    },
  },
}));
