import React from 'react';
import TextInput from '@bappweb/textfield';
import { makeStyles } from '@material-ui/core/styles';

const TextField = props => {
  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      <TextInput {...props} />
    </div>
  );
};

const useStyles = makeStyles({
  inputContainer: {
    position: 'relative',
    '& .bapp-form-input': {
      width: '100%',
      position: 'relative',
    },
    '& .bapp-input-container': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 1,
      padding: '16px 0 10px',
      margin: '0 20px',
    },
    '& .bapp-input-label': {
      fontSize: '12px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: '#5d5864',
    },
    '& .bapp-input': {
      marginTop: 8,
      border: 'none',
      outline: 0,
      background: 'none',
      paddingBottom: 8,
    },
    '& .bapp-input-container:not(.bapp-input-error)': {
      borderBottom: '1px solid #e6e5e9',
    },
    '& .bapp-input-error-border': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: '2px solid #ff6e64',
      pointerEvents: 'none',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '0',

      '& svg': {
        marginRight: 16,
      },
    },
    '& .bapp-message': {
      fontSize: 10,
      fontWeight: 600,
      padding: '4px 20px 8px',
      color: '#5d5864',
    },
  },
});

export default TextField;
