import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import CompanySvg from '../ui/icons/CompanySvg';
import { AppBar } from './AppBar';
import { UnAuthenticatedAppBar } from './UnAuthenticatedAppBar';
import NavLink from '../components/NavLink';
import { useAuthState } from 'ra-core';
import DataLayer from '../core/Ga_dataLayer';

export const Header = () => {
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.header}>
          <NavLink
            exact
            to="/"
            onClick={() => DataLayer('button_click', 'Logo', 'header', window.location.hash, '')}
            style={{ padding: '0 24px 0 0' }}
          >
            <CompanySvg />
          </NavLink>
          {authenticated && !loading && <AppBar />}
          {!authenticated && !loading && <UnAuthenticatedAppBar />}
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '60px',
    minHeight: '60px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.04)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
